import React, { useState, useCallback, useRef } from "react";

import LoadingSpinner from "./LoadingSpinner";
import History from "../History/HistoryView";
import FormFields from "./FormFields";
import ChildItemsList from "./ChildItemsList";
import TabSwitch from "./TabSwitch";
import gcsService from "../../services/db/GcsService";
import { convertToDTO as convertProjectsToDTO } from "../../types/projectsTypes";
import itemService from "../../services/db/ItemService";
const ItemEdit = ({
  item,
  onSave,
  onCancel,
  isNew,
  editMode,
  readOnly,
  itemType,
  preVersion,
  lastVersion,
  childItemType,
  ChildItemEdit,
  access,
}) => {
  const [editedItem, setEditedItem] = useState({
    ...item,
    tableType: itemType,
  });
  const [isChanged, setIsChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState("edit");
  const contentRef = useRef(null);

  const handleKeyDown = useCallback((e) => {
    try {
      if (e.key === "Enter" && e.target.tagName !== "TEXTAREA") {
        e.preventDefault();
      }
    } catch (error) {
      console.error("處理按鍵事件時發生錯誤:", error);
      setError("處理按鍵事件時發生錯誤");
    }
  }, []);

  const handleSubmit = useCallback(
    async (e) => {
      try {
        e?.preventDefault();
        if (!isNew && !isChanged) return null;

        setLoading(true);
        setError(null);

        let updatedItem = { ...editedItem };

        if (contentRef.current != null) {
          let url = await gcsService.uploadContent(
            contentRef.current,
            updatedItem.gcsdescriptionUrl
          );
          updatedItem.gcsdescriptionUrl = url.objectName;
        }
        let result = null;
        let dto;
        if (itemType === "Milestone" && Array.isArray(updatedItem.team)) {
          for (let member of updatedItem.team) {
            if (member.Type) {
              dto = await convertProjectsToDTO({
                tableType: "Type",
                data: member.Type,
              });
              dto.typeId = null;
              result = await itemService.createItem("Type", dto);
              member.roleType = result.data.typeId;
            }
            dto = await convertProjectsToDTO({
              tableType: "TeamMember",
              data: member,
            });
            result =
              typeof dto.id === "number" && !isNaN(dto.id)
                ? await itemService.updateItem("TeamMember", dto, "")
                : await itemService.createItem("TeamMember", dto);
          }
        }
        if (updatedItem.Type) {
          dto = await convertProjectsToDTO({
            tableType: "Type",
            data: updatedItem.Type,
          });
          dto.typeId = null;
          result = await itemService.createItem("Type", dto);
          updatedItem.typeId = result.data.typeId;
        }

        if (updatedItem.TypeCorrelation) {
          updatedItem.TypeCorrelation.idType = updatedItem.typeId;
          result = await itemService.createItem(
            "TypeCorrelation",
            updatedItem.TypeCorrelation
          );
        }
        if (updatedItem.StatusOption) {
          updatedItem.StatusOption.statusId = null;
          result = await itemService.createItem(
            "StatusOption",
            updatedItem.StatusOption
          );
          updatedItem.statusId = result.data.statusId;
        }
        dto = await convertProjectsToDTO({
          tableType: itemType,
          data: { ...updatedItem, type: null, status: null, assignee: null },
        });
        result =
          typeof dto.id === "number" && !isNaN(dto.id)
            ? await itemService.updateItem(itemType, dto, "")
            : await itemService.createItem(itemType, dto);
        await onSave(updatedItem);
        setIsChanged(false);
        setEditedItem(updatedItem);
        localStorage.setItem("newData", true);
        return result;
      } catch (error) {
        console.error(`${itemType} 儲存失敗:`, error);
        setError(`${itemType} 儲存失敗: ${error.message}`);
        return null;
      } finally {
        setLoading(false);
      }
    },
    [editedItem, onSave, isNew, isChanged, itemType]
  );

  const setContent = useCallback((content) => {
    try {
      contentRef.current = content;
      setIsChanged(true);
    } catch (error) {
      console.error("設置內容時發生錯誤:", error);
      setError("設置內容時發生錯誤");
    }
  }, []);
  if (loading && !editedItem.id) return <LoadingSpinner />;

  return (
    <div className="bg-white p-8 rounded-2xl shadow-2xl w-full relative">
      {isChanged && !readOnly && (
        <div className="absolute top-4 right-4 flex space-x-2">
          <button
            type="button"
            onClick={onCancel}
            className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50 transition-colors duration-150"
          >
            取消
          </button>
          <button
            onClick={handleSubmit}
            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition-colors duration-150"
            disabled={loading}
          >
            {loading ? "儲存中..." : "儲存"}
          </button>
        </div>
      )}
      <TabSwitch
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        isNew={isNew}
        itemType={itemType}
      />

      {activeTab === "edit" ? (
        <form
          onSubmit={handleSubmit}
          onKeyDown={handleKeyDown}
          className="space-y-8"
        >
          <FormFields
            editedItem={editedItem}
            setEditedItem={setEditedItem}
            setIsChanged={setIsChanged}
            itemType={itemType}
            setContent={setContent}
            readOnly={readOnly}
            preVersion={preVersion}
            lastVersion={lastVersion}
            access={access}
          />
          {isChanged && !readOnly && (
            <div className="flex justify-end space-x-2 pt-4">
              <button
                type="button"
                onClick={onCancel}
                className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50 transition-colors duration-150"
              >
                取消
              </button>
              <button
                type="button"
                onClick={handleSubmit}
                className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition-colors duration-150"
                disabled={loading}
              >
                {loading ? "儲存中..." : "儲存"}
              </button>
            </div>
          )}
          {!isNew && itemType !== "TaskAction" && (
            <ChildItemsList
              childItemType={childItemType}
              setError={setError}
              parentId={editedItem.id}
              parentItem={editedItem}
              readOnly={readOnly}
              preVersion={
                itemType === "Project"
                  ? null
                  : `${preVersion}${editedItem.version}`
              }
            />
          )}

          {error && <div className="text-red-500">{error}</div>}
        </form>
      ) : (
        <History
          item={editedItem}
          type={itemType}
          editMode={editMode}
          readOnly={readOnly}
          preVersion={preVersion}
          lastVersion={lastVersion}
          access={access}
        />
      )}
    </div>
  );
};

export default ItemEdit;
