import React from 'react';

const LoadingSpinner = ({ size = 16, color = '#3B82F6' }) => {
  return (
    <div className="flex items-center justify-center">
      <div 
        className="animate-spin rounded-full border-t-2 border-b-2"
        style={{
          width: `${size}px`,
          height: `${size}px`,
          borderColor: color
        }}
      ></div>
    </div>
  );
};

export default LoadingSpinner;