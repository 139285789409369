import React, { useEffect, useState, useRef } from 'react';

const ScrollArrow = ({ children, arrowSize = 24, arrowTopOffset = 0, arrowColor = 'gray-200', arrowHoverColor = 'gray-300' }) => {
  const [arrowTop, setArrowTop] = useState(0);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const contentRef = useRef(null);

  const checkOverflow = () => {
    const element = contentRef.current;
    if (element) {
      const showLeft = element.scrollLeft > 0;
      const showRight = element.scrollLeft + element.clientWidth < element.scrollWidth - 1;
      setShowLeftArrow(showLeft);
      setShowRightArrow(showRight);
    }
  };

  const handleScroll = () => {
    const element = contentRef.current;
    if (element) {
      const topPosition = element.getBoundingClientRect().top + window.scrollY;
      setArrowTop(topPosition + arrowTopOffset);
    }
  };

  useEffect(() => {
    const element = contentRef.current;
    if (element) {
      checkOverflow();
      handleScroll();
      window.addEventListener('resize', checkOverflow);
      element.addEventListener('scroll', checkOverflow);
      window.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (element) {
        window.removeEventListener('resize', checkOverflow);
        element.removeEventListener('scroll', checkOverflow);
        window.removeEventListener('scroll', handleScroll);
      }
    };
  });

  const scrollLeft = () => {
    const element = contentRef.current;
    if (element) {
      element.scrollBy({ left: -300, behavior: 'smooth' });
      setTimeout(checkOverflow, 300);
    }
  };

  const scrollRight = () => {
    const element = contentRef.current;
    if (element) {
      element.scrollBy({ left: 300, behavior: 'smooth' });
      setTimeout(checkOverflow, 300);
    }
  };

  const arrowStyle = {
    position: 'fixed',
    top: `${arrowTop}px`,
    zIndex: 10,
    fontSize: `${arrowSize}px`,
    padding: `${arrowSize / 4}px`,
  };

  return (
    <div className="flex items-start mb-4 flex-grow" style={{ position: 'relative' }}>
      {showLeftArrow && (
        <button
          onClick={scrollLeft}
          className={`rounded-full bg-${arrowColor} hover:bg-${arrowHoverColor}`}
          style={{ ...arrowStyle, left: '10px' }}
        >
          &larr;
        </button>
      )}
      <div ref={contentRef} className="overflow-x-auto flex-1" style={{ scrollbarWidth: 'thin', scrollbarColor: '#4A5568 #EDF2F7', paddingTop: '2rem' }}>
        {children}
      </div>
      {showRightArrow && (
        <button
          onClick={scrollRight}
          className={`rounded-full bg-${arrowColor} hover:bg-${arrowHoverColor}`}
          style={{ ...arrowStyle, right: '10px' }}
        >
          &rarr;
        </button>
      )}
    </div>
  );
};

export default ScrollArrow;
