import React, { useState, useEffect, useCallback, useMemo } from "react";
import indexedDBServiceInstance from "../../services/db/IndexedDBService";
import { GENERAL_STATUS_OPTIONS, ITEM_TYPES } from "../../data/constants";
import ScrollArrow from "../common/ScrollArrow";
import ItemCard from "../common/ItemCard";
import ItemEditModal from "../common/ItemEditModal";

const Kanbans = ({ projectId, initType = "Mission" }) => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [itemType, setItemType] = useState(initType);
  const [editingItem, setEditingItem] = useState(null);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [draggedOverStatus, setDraggedOverStatus] = useState(null);

  const allStatuses = useMemo(() => {
    const standardStatuses = new Set(
      GENERAL_STATUS_OPTIONS.map((option) => option.value)
    );
    const databaseStatuses = new Set(items.map((item) => item.status));
    return Array.from(new Set([...standardStatuses, ...databaseStatuses]));
  }, [items]);

  const loadItems = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      let allItems = [];
      let Project = [];
      let Milestone = [];
      switch (itemType) {
        case "Mission":
          if (projectId) {
            Project = [
              await indexedDBServiceInstance.get("Project", projectId),
            ];
            Milestone = await indexedDBServiceInstance.getByIndex(
              "Milestone",
              "parentId",
              projectId
            );

            if (Milestone) {
              const missionPromises = Milestone.map((milestone) =>
                indexedDBServiceInstance.getByIndex(
                  "Mission",
                  "parentId",
                  milestone.id
                )
              );

              const missionResults = await Promise.all(missionPromises);
              allItems = missionResults.flat();
            }
          } else {
            allItems = await indexedDBServiceInstance.getAll("Mission");
            Milestone = await indexedDBServiceInstance.getAll("Milestone");
            Project = await indexedDBServiceInstance.getAll("Project");
          }

          allItems = allItems.map((task) => {
            const milestone = Milestone.find((m) => m.id === task.parentId);
            const project = Project.find((p) => p.id === milestone?.parentId);
            return {
              ...task,
              milestoneName: milestone?.name,
              projectName: project?.name,
            };
          });
          break;

        case "Milestone":
          if (projectId) {
            Project = [
              await indexedDBServiceInstance.get("Project", projectId),
            ];
            allItems = await indexedDBServiceInstance.getByIndex(
              "Milestone",
              "parentId",
              projectId
            );
          } else {
            Project = await indexedDBServiceInstance.getAll("Project");
            allItems = await indexedDBServiceInstance.getAll("Milestone");
          }
          allItems = allItems.map((milestone) => {
            const project = Project.find((p) => p.id === milestone.parentId);
            return {
              ...milestone,
              projectName: project?.name || "Unknown",
            };
          });
          break;
        case "Project":
          allItems = await indexedDBServiceInstance.getAll("Project");
          if (projectId) {
            allItems = allItems.filter((project) => project.id === projectId);
          }
          break;
        default:
          break;
      }

      setItems(allItems);
    } catch (err) {
      console.error(`Error loading ${itemType}s:`, err);
      setError(`Failed to load ${itemType}s. Please try again.`);
    } finally {
      setLoading(false);
    }
  }, [projectId, itemType]);

  useEffect(() => {
    loadItems();
  }, [loadItems]);

  const moveItem = async (itemId, newStatus, permissions) => {
    try {
      if ((newStatus === "取消" || newStatus === "結案") && permissions <= 4) {
        alert("沒有權限將項目移動到該群組"); // 顯示錯誤消息
        return;
      }

      const itemToUpdate = items.find(
        (item) => item.id === itemId || item.id === parseInt(itemId, 10)
      );
      if (!itemToUpdate) {
        console.error(`${itemType} not found:`, itemId);
        return;
      }

      const updatedItem = {
        ...itemToUpdate,
        status: newStatus,
        tableType: itemType,
      };
      await indexedDBServiceInstance.addUpdateDirect(updatedItem);
      // await GanttChartService.saveItem(updatedItem);
      await loadItems();
    } catch (err) {
      console.error(`Error updating ${itemType}:`, err);
      setError(
        `Failed to update ${itemType}. Please try again. Error: ${err.message}`
      );
    }
  };

  const handleEditItem = (item) => {
    setEditingItem(item);
    setIsEditOpen(true);
  };

  const handleSaveEdit = async (updatedItem) => {
    try {
      setEditingItem(null);
      setIsEditOpen(false);
      await loadItems();
    } catch (err) {
      console.error("Error saving edited item:", err);
      setError("Failed to save edited item. Please try again.");
    }
  };

  const handleCancelEdit = () => {
    setEditingItem(null);
    setIsEditOpen(false);
  };

  const renderColumn = (status) => {
    const statusOption =
      GENERAL_STATUS_OPTIONS.find((option) => option.value === status) ||
      GENERAL_STATUS_OPTIONS[0];
    return (
      <div
        key={status}
        className={`p-4 rounded-lg ${
          statusOption.color
        } shadow-md min-w-[300px] w-80 ${
          draggedOverStatus === status ? "ring-4 ring-blue-500 z-10" : ""
        }`}
        onDragEnter={(e) => {
          e.preventDefault();
          e.target.style.cursor = "move";
          setDraggedOverStatus(status);
        }}
        onDragOver={(e) => {
          e.preventDefault();
          e.target.style.cursor = "move";
        }}
        onDragLeave={(e) => {
          e.target.style.cursor = "default";
          setDraggedOverStatus(null);
        }}
        onDrop={(e) => {
          e.preventDefault();
          e.target.style.cursor = "default";
          const data = e.dataTransfer.getData("text");
          if (data) {
            try {
              const parsedData = JSON.parse(data);
              const { id, permissions } = parsedData;
              if (
                (status === "取消" || status === "結案") &&
                permissions <= 4
              ) {
                alert("沒有權限將項目移動到該群組"); // 顯示錯誤消息
                return;
              }
              moveItem(id, status, permissions);
              setDraggedOverStatus(null);
            } catch (error) {
              console.error("解析拖曳數據失敗:", error);
            }
          }
        }}
      >
        <h2 className="text-xl font-bold mb-4 flex items-center sticky top-0 bg-opacity-90 backdrop-filter backdrop-blur-sm p-2 rounded">
          <span className="mr-2">{statusOption.icon}</span>
          {status}
        </h2>
        {items
          .filter((item) => item.status === status)
          .map((item) => (
            <ItemCard
              key={item.id}
              item={item}
              itemType={itemType}
              handleEditItem={handleEditItem}
              handleDelete={handleDelete}
              permissions={item.permissions} // 傳遞 permissions
            />
          ))}
      </div>
    );
  };

  const handleDelete = async (item) => {
    if (window.confirm(`確定要刪除 ${item.name} 嗎？`)) {
      try {
        await indexedDBServiceInstance.delete(item.itemType, item);
        await loadItems();
      } catch (error) {
        console.error("刪除失敗:", error);
        setError("刪除資料時發生錯誤");
      }
    }
  };

  return (
    <div className="flex flex-col h-full w-full p-6 justify-center">
      <div className="flex flex-col sm:flex-row justify-start items-center space-x-2 space-y-2 sm:space-y-0">
        {ITEM_TYPES.map((type) => (
          <button
            key={type.value}
            onClick={() => setItemType(type.value)}
            className={`px-4 py-2 rounded-md transition-colors duration-200 ${
              itemType === type.value
                ? "bg-blue-500 text-white"
                : "bg-white text-gray-700 hover:bg-gray-200"
            }`}
          >
            {type.label}
          </button>
        ))}
      </div>

      {loading ? (
        <div className="flex justify-center items-center h-64">
          <div
            className="animate-spin rounded-full border-t-2 border-b-2"
            style={{ width: "32px", height: "32px", borderColor: "#3B82F6" }}
          ></div>
        </div>
      ) : error ? (
        <div className="text-red-500 text-center py-4 text-xl">{error}</div>
      ) : (
        <ScrollArrow
          arrowSize={30}
          arrowTopOffset={10}
          arrowColor="blue-500"
          arrowHoverColor="blue-600"
        >
          <div className="flex flex-nowrap space-x-4 min-w-max pb-4">
            {allStatuses.map((status) => renderColumn(status))}
          </div>
        </ScrollArrow>
      )}
      {isEditOpen && editingItem && (
        <ItemEditModal
          currentItem={editingItem}
          ItemEditComponent="Mission"
          readOnly={false}
          tableType="Mission"
          parentId={editingItem.parentId}
          // setItemList={loadMissions}
          setErrorMessage={setError}
          parentContainerWidth={window.innerWidth}
          onSave={handleSaveEdit}
          onClose={handleCancelEdit}
        />
      )}
    </div>
  );
};

export default Kanbans;
