import itemService from "../../services/db/ItemService";
import { getUser } from "../../services/authService"; // Assuming this is where getUser comes from

export const accessCheck = async (type, id) => {
  try {
    const accessQ = await itemService.getAccessBool(type, id);
    if (Array.isArray(accessQ) && accessQ.length > 0) {
      // 只返回第一筆數據
      const firstAccess = accessQ[0];
      const currentUser = getUser();
      if (currentUser.userId === firstAccess.userId) {
        return {
          access: firstAccess.permission,
          readOnly: firstAccess.permission < 2,
        };
      }
    }
    return null; // 如果 accessQ 是空數組或不是數組，返回 null
  } catch (error) {
    console.error("Error in accessCheck:", error);
    return null; // 發生錯誤時返回 null
  }
};
