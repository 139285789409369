// IndexedDBService.js
import { DB_NAME, DB_VERSION, dbSchema } from "./IndexedDBConfig";
import { clearIndexedDB } from "./clearIndexedDB";
import syncService from "./syncService";
import { convertToDTO } from "../../types/projectsTypes";
import { getUser } from "../authService";
import itemService from "./ItemService";

import { generateUniqueId } from "../../utils/dateUtils";

class IndexedDBService {
  constructor() {
    this.db = null;
    this.currentUserId = null;
  }

  async init(userId) {
    try {
      const user = getUser();
      if (!user) return;
      this.currentUserId = user.userId;
      if (this.db && this.currentUserId === userId) {
        if (this.db.version >= DB_VERSION) {
          return this.db;
        } else {
          await clearIndexedDB();
          this.db = null;
        }
      }

      if (this.currentUserId !== userId && this.db != null) {
        await clearIndexedDB();
        this.db = null;
      }

      return new Promise((resolve, reject) => {
        const request = indexedDB.open(DB_NAME, DB_VERSION);

        request.onerror = (event) => {
          console.error("IndexedDB 錯誤:", event.target.error);
          reject("無法開啟數據庫");
        };

        request.onsuccess = (event) => {
          this.db = event.target.result;
          this.currentUserId = userId;
          resolve(this.db);
        };

        request.onupgradeneeded = (event) => {
          const db = event.target.result;
          this.createStores(db);
        };
      });
    } catch (err) {
      console.error("IndexedDB 初始化錯誤:", err);
      return null;
    }
  }

  createStores(db) {
    Object.entries(dbSchema.stores).forEach(([storeName, storeSchema]) => {
      if (!db.objectStoreNames.contains(storeName)) {
        const objectStore = db.createObjectStore(storeName, {
          keyPath: storeSchema.keyPath,
          autoIncrement: storeSchema.autoIncrement,
        });
        storeSchema.indexes.forEach((index) => {
          objectStore.createIndex(index.name, index.name, {
            unique: index.unique,
          });
        });
      }
    });
  }

  async executeTransaction(storeName, mode, callback) {
    try {
      const db = await this.init(this.currentUserId);
      return new Promise((resolve, reject) => {
        const transaction = db.transaction([storeName], mode);
        const store = transaction.objectStore(storeName);
        const request = callback(store);

        request.onerror = (event) =>
          reject(
            `${mode === "readwrite" ? "操作" : "獲取"} ${storeName} 失敗: ${
              event.target.error
            }`
          );
        request.onsuccess = (event) => resolve(event.target.result);
      });
    } catch (error) {
      console.error(`Error in executeTransaction for ${storeName}:`, error);
      throw error;
    }
  }

  async add(storeName, item) {
    return this.executeTransaction(storeName, "readwrite", (store) =>
      store.add(item)
    );
  }

  async get(storeName, key) {
    return this.executeTransaction(storeName, "readonly", (store) =>
      store.get(key)
    );
  }

  async getByIndex(storeName, indexName, value) {
    return this.executeTransaction(storeName, "readonly", (store) =>
      store.index(indexName).getAll(value)
    );
  }

  async getAll(storeName) {
    return this.executeTransaction(storeName, "readonly", (store) =>
      store.getAll()
    );
  }

  async put(storeName, item) {
    return this.executeTransaction(storeName, "readwrite", (store) =>
      store.put(item)
    );
  }

  async update(storeName, item) {
    return this.executeTransaction(storeName, "readwrite", (store) =>
      store.put(item)
    );
  }

  async delete(storeName, key) {
    try {
      const response = await itemService.deleteItem(storeName, key);
      console.log(response);
      const db = await this.init(this.currentUserId);
      return new Promise((resolve, reject) => {
        const transaction = db.transaction([storeName], "readwrite");
        const store = transaction.objectStore(storeName);

        const getRequest = store.get(key);

        getRequest.onsuccess = (event) => {
          const existingItem = event.target.result;
          if (existingItem !== undefined) {
            const deleteRequest = store.delete(key);
            deleteRequest.onsuccess = () => resolve(true);
            deleteRequest.onerror = (event) => {
              console.error(
                `刪除 ${storeName} 中的項目失敗，鍵值：${key}`,
                event.target.error
              );
              reject(`刪除 ${storeName} 失敗: ${event.target.error}`);
            };
          } else {
            resolve(false);
          }
        };

        getRequest.onerror = (event) => {
          console.error(
            `獲取 ${storeName} 中的項目失敗，鍵值：${key}`,
            event.target.error
          );
          reject(`獲取 ${storeName} 失敗: ${event.target.error}`);
        };

        transaction.onerror = (event) => {
          console.error(
            `Transaction failed for ${storeName}:`,
            event.target.error
          );
          reject(`事務失敗: ${event.target.error}`);
        };
      });
    } catch (error) {
      console.error(`Error in delete for ${storeName}:`, error);
      throw error;
    }
  }

  async clear(storeName) {
    return this.executeTransaction(storeName, "readwrite", (store) =>
      store.clear()
    );
  }

  async saveItem(storeName, item) {
    const db = await this.init(this.currentUserId);
    return new Promise((resolve, reject) => {
      const transaction = db.transaction([storeName], "readwrite");
      const store = transaction.objectStore(storeName);
      const convertedItem = { ...item };
      const keyPath = store.keyPath;
      let keyValue = Array.isArray(keyPath)
        ? keyPath.map((key) => convertedItem[key])
        : convertedItem[keyPath];

      if (convertedItem.updatedAt) {
        syncService.setLatestDataTime(convertedItem.updatedAt);
      }

      const getRequest = store.get(keyValue);
      getRequest.onsuccess = (event) => {
        const existingItem = event.target.result;
        let shouldUpdate = !existingItem;

        if (existingItem && convertedItem.updatedAt) {
          shouldUpdate =
            new Date(convertedItem.updatedAt) >
            new Date(existingItem.updatedAt);
        }

        if (shouldUpdate) {
          const request = store.put(convertedItem);
          request.onsuccess = () => resolve(convertedItem);
          request.onerror = (event) =>
            reject(`操作 ${storeName} 失敗: ${event.target.error}`);
        } else {
          resolve(existingItem);
        }
      };
      getRequest.onerror = (event) =>
        reject(`檢查 ${storeName} 鍵值失敗: ${event.target.error}`);
    });
  }

  async addUpdateDirect(item) {
    try {
      if (!item.tableType) {
        throw new Error("項目缺少 tableType");
      }
      const { filteredItem, operation } = await this.filterItemProperties(item);
      const keyPath = dbSchema.stores[item.tableType].keyPath;
      let uploadData = { ...filteredItem };

      if (operation === "create") {
        if (Array.isArray(keyPath)) {
          keyPath.forEach((key) => {
            uploadData[key] = null;
          });
        } else {
          uploadData[keyPath] = null;
        }
      }

      const dto = await convertToDTO({
        tableType: item.tableType,
        data: uploadData,
      });
      let response;

      switch (operation) {
        case "create":
          response = await itemService.createItem(item.tableType, dto);
          break;
        case "update":
          response = await itemService.updateItem(item.tableType, dto, "");
          break;
        default:
          throw new Error(`未知的操作類型: ${operation}`);
      }

      if (!response.success) {
        throw new Error(`上傳失敗: ${response.error}`);
      }

      const result = convertToDTO({
        tableType: item.tableType,
        data: response.data,
      });
      const savedItem = await this.saveItem(item.tableType, result);
      return savedItem;
    } catch (error) {
      console.error(`添加/更新項目失敗: ${item.tableType}`, error);
      throw error;
    }
  }

  async addList(storeName, items, currentUserId) {
    try {
      await this.init(currentUserId);
      if (!items || !Array.isArray(items) || items.length === 0) {
        return;
      }

      const savePromises = items.map((item) => this.saveItem(storeName, item));
      return Promise.all(savePromises);
    } catch (error) {
      console.error(`Error in addList for ${storeName}:`, error);
      throw error;
    }
  }

  getItemId(item, tableType) {
    const keyPath = dbSchema.stores[tableType].keyPath;
    if (Array.isArray(keyPath)) {
      return keyPath.map((key) => item[key]).join("|");
    } else {
      return item[keyPath];
    }
  }

  async filterItemProperties(item) {
    try {
      const storeSchema = dbSchema.stores[item.tableType];
      if (!storeSchema) {
        throw new Error(`未知的表類型: ${item.tableType}`);
      }

      const filteredItem = {};
      const allowedProperties = [
        storeSchema.keyPath,
        ...storeSchema.indexes.map((index) => index.name),
      ];

      for (const prop of allowedProperties) {
        if (item.hasOwnProperty(prop)) {
          filteredItem[prop] = item[prop];
        }
      }

      let operation;
      const keyPath = storeSchema.keyPath;

      const isNewKey = (value) => value === undefined;

      if (Array.isArray(keyPath)) {
        const keyValue = keyPath.map((key) => filteredItem[key]);
        if (keyValue.some(isNewKey)) {
          keyPath.forEach((key) => {
            if (isNewKey(filteredItem[key])) {
              filteredItem[key] = `temp_${generateUniqueId()}`;
            }
          });
          operation = "create";
        } else {
          const existingItem = await this.get(item.tableType, keyValue);
          operation = existingItem ? "update" : "create";
        }
      } else {
        if (isNewKey(filteredItem[keyPath])) {
          filteredItem[keyPath] = `temp_${generateUniqueId()}`;
          operation = "create";
        } else {
          const existingItem = await this.get(
            item.tableType,
            filteredItem[keyPath]
          );
          operation = existingItem ? "update" : "create";
        }
      }

      return { filteredItem, operation };
    } catch (error) {
      console.error("Error in filterItemProperties:", error);
      throw error;
    }
  }
}

const indexedDBServiceInstance = new IndexedDBService();
export default indexedDBServiceInstance;
