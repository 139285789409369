// MissionEdit.js
import React, { useState, useEffect } from "react";
import ItemEdit from "../common/ItemEdit";
import ActionEdit from "./ActionEdit";
import itemService from "../../services/db/ItemService";
import { getCurrentLocalDate } from "../../utils/dateUtils";
import { accessCheck } from "../common/Access";
import { Typography } from "@mui/material";
const MissionEdit = ({ item, onSave, onCancel, isNew, editMode }) => {
  const [preVersion, setPreVersion] = useState("v?");
  const [lastVersion, setLastVersion] = useState(0);
  const [access, setAccess] = useState(0);
  const [readOnly, setReadOnly] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [editingItem, setEditingItem] = useState(null);

  useEffect(() => {
    const fetchVersions = async () => {
      if (item.parentId) {
        try {
          const result = await itemService.getVersion("Mission", item.parentId);
          if (Array.isArray(result) && result.length > 0) {
            const version = result[0];
            setLastVersion(version.maxVersion ?? 0);
            setPreVersion(version.preVersion ?? "v?");
          }

          if (isNew) {
            setAccess(5);
            setReadOnly(false);
          } else {
            const accessQ = await accessCheck("Mission", item.id);
            if (accessQ) {
              setAccess(accessQ.access);
              setReadOnly(accessQ.readOnly);
            }
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    };
    const checkItemInfo = async () => {
      let tempItem = { ...item, tableType: "Mission" };
      if (!item.id) {
        tempItem.name = "新任務";
        tempItem.version = lastVersion;
        tempItem.startDate = getCurrentLocalDate();
      }
      setEditingItem(tempItem);
    };
    checkItemInfo();
    fetchVersions();
  }, [item, isNew, setEditingItem, lastVersion]);

  const handleSave = (updatedItem) => {
    if (access >= 2) {
      onSave(updatedItem);
    } else {
      console.warn("權限不足");
    }
  };

  if (isLoading) {
    return (
      <Typography variant="h6" align="center">
        載入中...
      </Typography>
    );
  }
  return (
    <ItemEdit
      item={editingItem}
      onSave={handleSave}
      onCancel={onCancel}
      isNew={isNew}
      editMode={editMode}
      readOnly={readOnly}
      itemType={item.tableType}
      preVersion={preVersion}
      lastVersion={lastVersion}
      ChildItemEdit={ActionEdit}
      childItemType="TaskAction"
      access={access}
    />
  );
};
export default MissionEdit;

/*
    public enum PermissionType
    {
        DeleteItem = 5,
        DeleteSubItems = 4,
        AddSubItems = 3,
        WriteItem = 2,
        ReadSubItems = 1,
        ReadItem = 0
    }
*/
