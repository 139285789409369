import React, { useState, useEffect } from 'react';
import indexedDBServiceInstance from '../../services/db/IndexedDBService';

const ItemCard = ({ item, itemType, handleEditItem, handleDelete }) => {
  const [permissions, setPermissions] = useState(null);

  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const result = await indexedDBServiceInstance.get('AccessList', [itemType, item.id]);
        if (result) {
          setPermissions(result.permission);
        } else {
          setPermissions(0); // 或其他默認值
        }
      } catch (error) {
        console.error('查詢權限失敗:', error);
        setPermissions(0); // 或其他默認值
      }
    };

    fetchPermissions();
  }, [itemType, item.id]);

  const renderHierarchy = () => {
    switch (itemType) {
      case 'TaskAction':
        return `${item.projectName || 'N/A'} > ${item.milestoneName || 'N/A'} > ${item.taskName || 'N/A'}`;
      case 'Mission':
        return `${item.projectName || 'N/A'} > ${item.milestoneName || 'N/A'}`;
      case 'Milestone':
        return `${item.projectName || 'N/A'}`;
      default:
        return '';
    }
  };

  if (permissions === null) {
    // 可以在這裡添加一個 loading 狀態的 UI
    return null;
  }

  return (
    <div
      key={item.id}
      className={`p-4 mb-4 rounded-lg shadow-md bg-white relative ${permissions > 1 ? 'cursor-pointer' : 'cursor-not-allowed'}`}
      draggable={permissions > 1} // 根據 permissions 設置 draggable
      onDragStart={(e) => {
        if (permissions > 1) {
          const dragData = JSON.stringify({ id: item.id, permissions });
          e.dataTransfer.setData('text/plain', dragData);
          e.dataTransfer.effectAllowed = 'move'; // 設置拖曳效果
        } else {
          e.preventDefault(); // 防止拖曳事件的預設行為
        }
      }}
    >
      <div className="absolute top-2 right-2 flex space-x-2">
        {permissions > 1 && (
          <button
            onClick={() => handleEditItem(item)}
            className="text-blue-600 hover:text-blue-800 transition-colors duration-200"
          >
            ✏️
          </button>
        )}
        {permissions > 4 && (
          <button
            onClick={() => handleDelete(item)}
            className="text-red-600 hover:text-red-800 transition-colors duration-200"
          >
            🗑️
          </button>
        )}
      </div>
      <h3 className="font-bold text-lg mb-2 pr-16">{item.name}</h3>
      <p className="text-sm text-gray-600 mb-2">{renderHierarchy()}</p>
      <p className="text-sm mb-2">{new Date(item.startDate).toLocaleDateString()} ~ {new Date(item.endDate).toLocaleDateString()}</p>
      {itemType === 'action' && (
        <p className="text-sm mb-2">{new Date(item.startDate).toLocaleTimeString()} ~ {new Date(item.endDate).toLocaleTimeString()}</p>
      )}
    </div>
  );
};

export default ItemCard;
