import React, { useMemo, useCallback, useEffect, useState } from "react";

import {
  TextField,
  Typography,
  Box,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import StatusSelector from "./Selector/StatusSelector";
import AssigneeSelector from "./Selector/AssigneeSelector";
import TypeSelector from "./Selector/TypeSelector";
import PrioritySelector from "./Selector/PrioritySelector";
import QuillEditor from "./QuillEditor";
import CompanySelector from "./Selector/CompanySelector";
import TeamFormation from "./TeamMember/TeamFormation";
import CustomDateTimePicker from "./DatetimePicker";

// Create a custom theme
const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-root": {
            height: "40px",
          },
          "& .MuiInputLabel-root": {
            transform: "translate(14px, -9px) scale(0.75)",
            background: "#fff",
            padding: "0 4px",
          },
          "& .MuiInputLabel-shrink": {
            transform: "translate(14px, -9px) scale(0.75)",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          height: "40px",
        },
        input: {
          padding: "10px 14px",
        },
      },
    },
  },
});

const formatNumber = (value) => {
  if (value === null || value === undefined || value === "") return "";
  const number = parseFloat(value);
  if (isNaN(number)) return value;
  if (Number.isInteger(number)) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return number.toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const parseNumber = (value) => {
  if (value === null || value === undefined || value === "") return "";
  const parsedValue = parseFloat(value.replace(/,/g, ""));
  return isNaN(parsedValue) ? "" : parsedValue;
};

const FormFields = ({
  editedItem,
  setEditedItem,
  setIsChanged,
  itemType,
  setContent,
  preVersion,
  lastVersion,
  readOnly = true,
  access,
}) => {
  const [localCost, setLocalCost] = useState(formatNumber(editedItem.cost) || "");
  const [localBudget, setLocalBudget] = useState(formatNumber(editedItem.budget) || "");

  useEffect(() => {
    setLocalCost(formatNumber(editedItem.cost) || "");
    setLocalBudget(formatNumber(editedItem.budget) || "");
  }, [editedItem.cost, editedItem.budget]);

  useEffect(() => {
    if (isNaN(editedItem.version) && lastVersion) {
      setEditedItem((prev) => ({ ...prev, version: lastVersion }));
    }
  }, [lastVersion, editedItem.version, setEditedItem]);

  useEffect(() => {
    if (itemType === "TaskAction" && editedItem.typeId !== 76) {
      const startDate = new Date(editedItem.startDate);
      const endDate = editedItem.endDate ? new Date(editedItem.endDate) : new Date();
      if (startDate && endDate) {
        const diffInHours = Number(((endDate - startDate) / (1000 * 60 * 60)).toFixed(1));
        setEditedItem((prev) => ({
          ...prev,
          cost: diffInHours,
        }));
        setLocalCost(formatNumber(diffInHours));
        setIsChanged(true);
      }
    }
  }, [itemType, editedItem.typeId, editedItem.startDate, editedItem.endDate, setEditedItem, setIsChanged]);

  const handleDateChange = useCallback(
    (field, date) => {
      if (readOnly) return;
      setEditedItem((prev) => ({
        ...prev,
        [field]: date,
      }));
      setIsChanged(true);
    },
    [readOnly, setEditedItem, setIsChanged]
  );

  const handleInputChange = useCallback(
    (e) => {
      if (readOnly) return;

      const { name, value } = e.target;
      let processedValue = value;

      if (name === "version_field") {
        const parsedValue = parseInt(value);
        processedValue = isNaN(parsedValue) ? "" : Math.max(0, parsedValue);
        setEditedItem((prev) => ({
          ...prev,
          [name.replace("_field", "")]: processedValue,
        }));
      } else if (name === "budget_field" || name === "cost_field") {
        // Allow empty string, numbers, and a single decimal point
        if (/^$|^[0-9]*\.?[0-9]*$/.test(value)) {
          if (name === "budget_field") {
            setLocalBudget(value);
          } else {
            setLocalCost(value);
          }
        } else {
          return; // Invalid input, don't update state
        }
      } else {
        setEditedItem((prev) => ({
          ...prev,
          [name.replace("_field", "")]: processedValue,
        }));
      }
      setIsChanged(true);
    },
    [readOnly, setEditedItem, setIsChanged]
  );

  const handleBlur = useCallback(
    (e) => {
      const { name, value } = e.target;
      if (name === "budget_field" || name === "cost_field") {
        const parsedValue = parseNumber(value);
        setEditedItem((prev) => ({
          ...prev,
          [name.replace("_field", "")]: parsedValue,
        }));
        if (name === "budget_field") {
          setLocalBudget(formatNumber(parsedValue));
        } else {
          setLocalCost(formatNumber(parsedValue));
        }
      }
    },
    [setEditedItem]
  );

  const handleTeamFormationComplete = useCallback(
    (team) => {
      if (readOnly || itemType !== "Milestone") return;
      setEditedItem((prev) => ({
        ...prev,
        team: team,
      }));
      setIsChanged(true);
    },
    [readOnly, setEditedItem, setIsChanged, itemType]
  );

  const handleSelectChange = useCallback(
    (field, selectedItem) => {
      if (readOnly) return;

      let updates = {};

      switch (field) {
        case "Status":
          updates = {
            StatusOption: selectedItem.StatusOption,
            statusId: selectedItem.id,
          };
          break;
        case "Priority":
          updates = { priority: selectedItem.id };
          break;
        case "Assignee":
          updates = {
            assigneeId: selectedItem.assigneeId,
            accountId: selectedItem.accountId,
          };
          break;
        case "Type":
          updates = {
            typeId: selectedItem.id,
            Type: selectedItem.Type,
            TypeCorrelation: selectedItem.TypeCorrelation,
          };
          break;
        case "Company":
          updates = { parentId: selectedItem.id };
          break;
        case "Team":
          updates = { teamSummary: selectedItem };
          break;
        default:
          console.warn(`Unhandled field type: ${field}`);
      }

      setEditedItem((prev) => ({ ...prev, ...updates }));
      setIsChanged(true);
    },
    [readOnly, setEditedItem, setIsChanged]
  );

  const commonFieldProps = useMemo(
    () => ({
      fullWidth: true,
      size: "small",
      slotProps: {
        input: {
          readOnly: readOnly,
          style: { height: "40px" },
        },
      },
      InputLabelProps: {
        shrink: true,
      },
    }),
    [readOnly]
  );

  const getCostLabel = useCallback(() => {
    if (itemType === "TaskAction") {
      return editedItem.typeId === 76 ? "成本" : "持續時間（小時）";
    }
    return "成本";
  }, [itemType, editedItem.typeId]);

  const fields = useMemo(() => {
    const baseFields = [
      {
        id: "type",
        label: "類型",
        component: (
          <TypeSelector
            label="類型"
            currentTypeId={editedItem.typeId}
            onTypeChange={(selectedItem) =>
              handleSelectChange("Type", selectedItem)
            }
            readOnly={readOnly}
            itemType={itemType}
            parentId={editedItem.parentId}
            height="40px"
            inputProps={{ autoComplete: "off" }}
          />
        ),
      },
      {
        id: "assignee",
        label: "負責人",
        component: (
          <AssigneeSelector
            label="負責人"
            currentAssigneeId={editedItem.assigneeId}
            onAssigneeChange={(selectedItem) =>
              handleSelectChange("Assignee", selectedItem)
            }
            readOnly={readOnly}
            itemType={itemType}
            item={editedItem}
            height="40px"
            inputProps={{ autoComplete: "off" }}
          />
        ),
      },
      {
        id: "status",
        label: "狀態",
        component: (
          <StatusSelector
            label="狀態"
            currentStatusId={editedItem.statusId || editedItem.status?.statusId}
            onStatusChange={(selectedItem) =>
              handleSelectChange("Status", selectedItem)
            }
            readOnly={readOnly}
            itemType={itemType}
            height="40px"
            inputProps={{ autoComplete: "off" }}
          />
        ),
      },
      {
        id: "priority",
        label: "優先順序",
        component: (
          <PrioritySelector
            label="優先順序"
            currentPriorityId={editedItem.priority}
            onPriorityChange={(selectedItem) =>
              handleSelectChange("Priority", selectedItem)
            }
            readOnly={readOnly}
            height="40px"
            inputProps={{ autoComplete: "off" }}
          />
        ),
      },
      {
        id: "budget",
        label: "預算",
        component: (
          <TextField
            {...commonFieldProps}
            type="text"
            name="budget_field"
            label="預算"
            value={localBudget}
            onChange={handleInputChange}
            onBlur={handleBlur}
            disabled={true}
            slotProps={{ htmlInput: { autoComplete: "off" } }}
          />
        ),
      },
      {
        id: "cost",
        label: getCostLabel(),
        component: (
          <TextField
            {...commonFieldProps}
            type="text"
            name="cost_field"
            label={getCostLabel()}
            value={localCost}
            onChange={handleInputChange}
            onBlur={handleBlur}
            disabled={!(itemType === "TaskAction" && editedItem.typeId === 76)}
            slotProps={{ htmlInput: { autoComplete: "off" } }}
          />
        ),
      },
    ];

    if (itemType === "Project" && !editedItem.id) {
      baseFields.unshift({
        id: "company",
        label: "公司",
        component: (
          <CompanySelector
            label="客戶"
            onCompanyChange={(selectedItem) =>
              handleSelectChange("Company", selectedItem)
            }
            readOnly={readOnly}
            height="40px"
            inputProps={{ autoComplete: "off" }}
          />
        ),
      });
    }

    if (itemType === "TaskAction") {
      return baseFields.filter(
        (field) => !["status", "priority", "budget"].includes(field.id)
      );
    }

    return baseFields;
  }, [
    itemType,
    editedItem,
    readOnly,
    handleSelectChange,
    handleInputChange,
    handleBlur,
    commonFieldProps,
    getCostLabel,
    localBudget,
    localCost,
  ]);

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ p: 2 }}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Box sx={{ display: "flex", gap: 2 }}>
            <Box sx={{ flexGrow: 1 }}>
              <TextField
                {...commonFieldProps}
                label="名稱"
                name="name_field"
                value={editedItem.name || ""}
                onChange={handleInputChange}
                disabled={readOnly}
                slotProps={{ htmlInput: { autoComplete: "new-password" } }}
              />
            </Box>
            {itemType !== "Project" && (
              <Box sx={{ width: "30%" }}>
                <TextField
                  {...commonFieldProps}
                  label="版本"
                  type="number"
                  name="version_field"
                  value={(editedItem.version ?? 0).toString()}
                  onChange={handleInputChange}
                  disabled={readOnly}
                  slotProps={{
                    ...commonFieldProps.slotProps,
                    input: {
                      ...commonFieldProps.slotProps.input,
                      startAdornment: (
                        <Typography variant="body2" sx={{ mr: 1 }}>
                          {preVersion}.
                        </Typography>
                      ),
                      autoComplete: "new-password",
                    },
                  }}
                />
              </Box>
            )}
          </Box>

          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
            {fields.map((field) => (
              <Box
                key={field.id}
                sx={{
                  width: {
                    xs: "100%",
                    sm: "calc(50% - 8px)",
                    md: "calc(33.33% - 10.67px)",
                  },
                }}
              >
                {field.component}
              </Box>
            ))}
          </Box>

          {itemType === "Milestone" && editedItem.id && (
            <Box>
              <Typography variant="subtitle2" gutterBottom>
                團隊資訊
              </Typography>
              <TeamFormation
                onComplete={handleTeamFormationComplete}
                milestoneId={editedItem.id}
                milestone={editedItem}
                readOnly={readOnly}
              />
            </Box>
          )}

<Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              gap: 2,
            }}
          >
            <Box sx={{ flexGrow: 1 }}>
              <CustomDateTimePicker
                label={itemType === "TaskAction" ? "開始時間" : "開始日期"}
                value={editedItem.startDate}
                readOnly={readOnly}
                height="40px"
                includeTime={itemType === "TaskAction"}
                onChange={(date) => handleDateChange("startDate", date)}
                slotProps={{ htmlInput: { autoComplete: "off" } }}
              />
            </Box>
            <Box sx={{ flexGrow: 1 }}>
              <CustomDateTimePicker
                label={itemType === "TaskAction" ? "結束時間" : "結束日期"}
                value={editedItem.endDate}
                readOnly={readOnly}
                height="40px"
                includeTime={itemType === "TaskAction"}
                onChange={(date) => handleDateChange("endDate", date)}
                slotProps={{ htmlInput: { autoComplete: "off" } }}
              />
            </Box>
          </Box>

          {itemType !== "TaskAction" && (
            <TextField
              {...commonFieldProps}
              label="雲端資料夾"
              name="gcsfolderUrl_field"
              value={editedItem.gcsfolderUrl || ""}
              onChange={handleInputChange}
              disabled={access < 4}
              slotProps={{ htmlInput: { autoComplete: "new-password" } }}
            />
          )}

          <Box>
            <Typography variant="subtitle2" gutterBottom>
              描述
            </Typography>
            <QuillEditor
              url={editedItem.gcsdescriptionUrl}
              value={null}
              onChange={setContent}
              minHeight={150}
              readOnly={readOnly}
            />
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default FormFields;