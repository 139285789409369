import React, { useState, useEffect, useCallback, useRef } from "react";
import { useLocation } from "react-router-dom";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  Navigate,
} from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { getCurrentUser, verifyToken } from "./services/authService";
import { getLatestDataTime } from "./services/db/syncService";
import Home from "./components/project/Home";
import DynamicGanttChart from "./components/project/DynamicGanttChart";
import Missions from "./components/project/Missions";
import Kanbans from "./components/project/Kanbans";
import ProjectManagement from "./components/project/ProjectManagement";
import ActionTimer from "./components/common/ActionTimer";
import GoogleAuthButton from "./components/auth/GoogleAuthButton";
import ProfileEdit from "./components/Profile/ProfileComponent";
import TaskActionTimeList from "./components/project/TaskActionTimeList";
import ProjectTreeList from "./components/project/ProjectTreeList";
import { Box, CircularProgress } from "@mui/material";
import { getCurrentUserLevel } from "./services/authService";
import ClientManagement from "./components/project/ClientManagement";
import itemService from "./services/db/ItemService";
import SupplyChainManagement from "./components/project/SupplyChainManagement";
import BudgetManagement from "./components/project/BudgetManagement";

import {
  trackPageView,
  trackPageExit,
  isPageRefresh,
} from "./services/trackingService";
function App() {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <Router>
        <AppContent />
      </Router>
    </GoogleOAuthProvider>
  );
}

function AppContent() {
  const [editMode, setEditMode] = useState(() => {
    localStorage.setItem("editMode", "popup");
    return localStorage.getItem("editMode") || "popup";
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const [editingItem, setEditingItem] = useState(null);
  const [googleStatus, setGoogleStatus] = useState(null);
  const componentStates = useRef({});
  const location = useLocation();
  const lastPathRef = useRef(location.pathname);
  const pageEnterTimeRef = useRef(new Date());
  const isRefreshRef = useRef(false);

  const handleLogout = useCallback(() => {
    setIsAuthenticated(false);
    setUser(null);
    window.location.reload(true);
  }, []);

  useEffect(() => {
    const forceLogoutListener = () => {
      console.log("檢測到強制登出事件");
      handleLogout();
    };

    itemService.on("forceLogout", forceLogoutListener);

    return () => {
      itemService.off("forceLogout", forceLogoutListener);
    };
  }, [handleLogout]);

  const refreshCurrentComponent = useCallback(() => {
    setRefreshTrigger((prev) => prev + 1);
  }, []);

  const handleAuthChange = useCallback(
    async (isAuth) => {
      setIsAuthenticated(isAuth);
      if (isAuth) {
        const currentUser = getCurrentUser();
        setUser(currentUser);
      } else {
        setUser(null);
      }
      setGoogleStatus(null);
      refreshCurrentComponent();
    },
    [refreshCurrentComponent]
  );

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const { isValid } = await verifyToken();
        handleAuthChange(isValid);
      } catch (error) {
        console.error("Authentication check failed:", error);
        handleAuthChange(false);
      } finally {
        setIsLoading(false);
      }
    };

    checkAuth();
  }, [handleAuthChange]);

  useEffect(() => {
    const syncAndRefresh = async () => {
      if (isAuthenticated) {
        const oldLatestDataTime = getLatestDataTime();
        const newLatestDataTime = getLatestDataTime();
        if (newLatestDataTime !== oldLatestDataTime) {
          refreshCurrentComponent();
        }
      }
    };

    // Initial sync when authenticated
    if (isAuthenticated) {
      syncAndRefresh();
    }

    // Set up intervals for regular syncing
    const shortInterval = setInterval(() => {
      if (localStorage.getItem("newData") === "true") {
        localStorage.setItem("newData", "false");
        syncAndRefresh();
      }
    }, 100);

    const fiveMinuteInterval = setInterval(() => {
      syncAndRefresh();
    }, 5 * 60 * 1000); // 5 minutes in milliseconds

    return () => {
      clearInterval(shortInterval);
      clearInterval(fiveMinuteInterval);
    };
  }, [isAuthenticated, refreshCurrentComponent]);

  useEffect(() => {
    if (isAuthenticated && user) {
      const currentPath = location.pathname;
      const currentTime = new Date();
      isRefreshRef.current = isPageRefresh();

      if (currentPath !== lastPathRef.current) {
        if (!isRefreshRef.current) {
          const duration = currentTime - pageEnterTimeRef.current;
          trackPageExit(lastPathRef.current, duration);
          trackPageView(currentPath);
        } else {
          trackPageView(currentPath, true);
        }

        lastPathRef.current = currentPath;
        pageEnterTimeRef.current = currentTime;
      }
    }
  }, [location, isAuthenticated, user]);

  // 在組件卸載時記錄最後一個頁面的停留時間
  useEffect(() => {
    return () => {
      if (isAuthenticated && user && !isRefreshRef.current) {
        const exitTime = new Date();
        const duration = exitTime - pageEnterTimeRef.current;
        trackPageExit(lastPathRef.current, duration);
      }
    };
  }, [isAuthenticated, user]);

  const toggleEditMode = useCallback(() => {
    setEditMode((prevMode) => {
      const newMode = prevMode === "popup" ? "drawer" : "popup";
      localStorage.setItem("editMode", newMode);
      return newMode;
    });
  }, []);

  const saveComponentState = useCallback((componentName, state) => {
    componentStates.current[componentName] = state;
  }, []);

  const getComponentState = useCallback((componentName) => {
    return componentStates.current[componentName] || {};
  }, []);

  if (googleStatus) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        {googleStatus}
        <CircularProgress />
      </Box>
    );
  }
  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        資料讀取中.....
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div className="App flex flex-col min-h-screen">
      <header className="bg-gray-800 text-white p-4 fixed top-0 left-0 right-0 z-50">
        <div className="container mx-auto flex justify-between items-center">
          {isAuthenticated ? (
            <>
              <nav>
                <ul className="flex space-x-4">
                  <li>
                    <Link to="/" className="hover:underline">
                      首頁
                    </Link>
                  </li>
                  <li>
                    <Link to="/projecttreelist" className="hover:underline">
                      樹狀圖
                    </Link>
                  </li>
                  <li>
                    <Link to="/tasks" className="hover:underline">
                      任務
                    </Link>
                  </li>

                  <li>
                    <Link to="/taskActionTimeList" className="hover:underline">
                      工時列表
                    </Link>
                  </li>
                  {false ? (
                    <li>
                      <Link to="/gantt" className="hover:underline">
                        甘特圖
                      </Link>
                    </li>
                  ) : null}
                  {false ? (
                    <li>
                      <Link to="/kanbans" className="hover:underline">
                        看板
                      </Link>
                    </li>
                  ) : null}

                  <li>
                    <Link to="/projectmanagement" className="hover:underline">
                      專案管理
                    </Link>
                  </li>

                  <li>
                    <Link to="/budgetManagement" className="hover:underline">
                      預算管理
                    </Link>
                  </li>
                  {getCurrentUserLevel() > 100 ? (
                    <li>
                      <Link to="/clientmanagement" className="hover:underline">
                        公司管理
                      </Link>
                    </li>
                  ) : null}
                  {getCurrentUserLevel() > 100 ? (
                    <li>
                      <Link
                        to="/supplyChainManagement"
                        className="hover:underline"
                      >
                        供應鏈管理
                      </Link>
                    </li>
                  ) : null}
                </ul>
              </nav>
            </>
          ) : null}
          <div className="flex items-center space-x-4">
            {isAuthenticated ? (
              <>
                <span className="mr-2">編輯模式：</span>
                <button
                  onClick={toggleEditMode}
                  className={`px-4 py-2 rounded-md transition-colors duration-200 ${
                    editMode === "popup"
                      ? "bg-blue-500 text-white"
                      : "bg-gray-300 text-gray-800"
                  }`}
                >
                  {editMode === "popup" ? "彈出式" : "抽屜式"}
                </button>
              </>
            ) : null}
            <GoogleAuthButton
              isAuthenticated={isAuthenticated}
              onAuthChange={handleAuthChange}
              user={user}
              setStatus={setGoogleStatus}
            />
          </div>
        </div>
      </header>
      <div className="fixed top-16 right-4 z-50 w-auto max-w-2xl">
        <ActionTimer />
      </div>
      <main className="container mx-auto mt-20 flex-grow p-4 flex flex-col w-full max-w-full">
        <Routes>
          <Route
            path="/"
            element={
              isAuthenticated ? (
                <Home
                  key={`home-${refreshTrigger}`}
                  saveState={saveComponentState}
                  getState={getComponentState}
                />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            path="/gantt"
            element={
              isAuthenticated && getCurrentUserLevel() > 100 ? (
                <DynamicGanttChart
                  key={`gantt-${refreshTrigger}`}
                  editMode={editMode}
                  saveState={saveComponentState}
                  getState={getComponentState}
                />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            path="/tasks"
            element={
              isAuthenticated ? (
                <Missions
                  key={`tasks-${refreshTrigger}`}
                  editMode={editMode}
                  saveState={saveComponentState}
                  getState={getComponentState}
                />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            path="/kanbans"
            element={
              isAuthenticated && getCurrentUserLevel() > 100 ? (
                <Kanbans
                  key={`kanbans-${refreshTrigger}`}
                  editMode={editMode}
                  saveState={saveComponentState}
                  getState={getComponentState}
                />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            path="/projectmanagement"
            element={
              isAuthenticated ? (
                <ProjectManagement
                  key={`projectmanagement-${refreshTrigger}`}
                  editMode={editMode}
                  saveState={saveComponentState}
                  getState={getComponentState}
                />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            path="/clientmanagement"
            element={
              isAuthenticated && getCurrentUserLevel() > 100 ? (
                <ClientManagement
                  key={`clientmanagement-${refreshTrigger}`}
                  editMode={editMode}
                  saveState={saveComponentState}
                  getState={getComponentState}
                />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            path="/budgetManagement"
            element={
              <BudgetManagement
                key={`budgetManagement-${refreshTrigger}`}
                editMode={editMode}
                saveState={saveComponentState}
                getState={getComponentState}
              />
            }
          />

          <Route
            path="/supplyChainManagement"
            element={
              isAuthenticated && getCurrentUserLevel() > 100 ? (
                <SupplyChainManagement
                  key={`supplyChainManagement-${refreshTrigger}`}
                  editMode={editMode}
                  saveState={saveComponentState}
                  getState={getComponentState}
                />
              ) : (
                <Navigate to="/" />
              )
            }
          />

          <Route
            path="/taskActionTimeList"
            element={
              isAuthenticated ? (
                <TaskActionTimeList
                  editMode={editMode}
                  editingItem={editingItem}
                  setEditingItem={setEditingItem}
                  refreshTrigger={refreshTrigger}
                  saveState={saveComponentState}
                  getState={getComponentState}
                />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            path="/projecttreelist"
            element={
              isAuthenticated ? (
                <ProjectTreeList
                  editMode={editMode}
                  editingItem={editingItem}
                  setEditingItem={setEditingItem}
                  refreshTrigger={refreshTrigger}
                  saveState={saveComponentState}
                  getState={getComponentState}
                />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            path="/profile"
            element={
              isAuthenticated ? (
                <ProfileEdit
                  key={`profile-${refreshTrigger}`}
                  saveState={saveComponentState}
                  getState={getComponentState}
                />
              ) : (
                <Navigate to="/" />
              )
            }
          />
        </Routes>
      </main>
      <footer className="bg-gray-800 text-white p-4">
        <div className="container mx-auto text-center">
          © 2024 毛多多股份有限公司. 版權所有.
        </div>
      </footer>
    </div>
  );
}

export default App;
