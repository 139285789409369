// ProjectEdit.js
import React, { useState, useEffect } from 'react';
import ItemEdit from '../common/ItemEdit';
import MilestoneEdit from './MilestoneEdit';
import {getCurrentLocalDate} from '../../utils/dateUtils'
import {accessCheck} from '../common/Access'

const ProjectEdit = ({ item, onSave, onCancel, isNew, editMode }) => {
  const [preVersion, setPreVersion] = useState(null);
  const [lastVersion] = useState(0);
  const [access, setAccess] = useState(0);
  const [readOnly, setReadOnly] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [editingItem, setEditingItem] = useState(null);
  useEffect(() => {
    const fetchVersions = async () => {
      try {
        setPreVersion(`V`);
        if(isNew){
          setAccess(5);
          setReadOnly(false);
        }
        else{
          const accessQ = await  accessCheck('Project', item.id);
          if (accessQ) {          
            setAccess(accessQ.access);
            setReadOnly(accessQ.readOnly);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };
    const checkItemInfo = async () => {
      let tempItem = { ...item, tableType: 'Project' };
      if (!item.id) {
        tempItem.name = "新專案";
        tempItem.version = lastVersion;
        tempItem.startDate = getCurrentLocalDate();
      }
      setEditingItem(tempItem);
    }
    checkItemInfo();
    fetchVersions();
  }, [item, isNew,lastVersion]);

  const handleSave = (updatedItem) => {
    if (access >= 2) {  
      onSave(updatedItem);
    } else {
      console.warn("權限不足");
    }
  };
  if (isLoading) {
    return <div>Loading...</div>;
  }
  return (
    <ItemEdit
      item={editingItem}
      onSave={handleSave}
      onCancel={onCancel}
      isNew={isNew}
      editMode= {editMode}
      readOnly= {readOnly}
      itemType={item.tableType}
      preVersion={preVersion}
      lastVersion={lastVersion}
      ChildItemEdit={MilestoneEdit}
      childItemType="Milestone"
      access={access}
    />
  );
};

export default ProjectEdit;

/*
    public enum PermissionType
    {
        DeleteItem = 5,
        DeleteSubItems = 4,
        AddSubItems = 3,
        WriteItem = 2,
        ReadSubItems = 1,
        ReadItem = 0
    }
*/