import indexedDBServiceInstance from './db/IndexedDBService';

class GanttChartService {
  async loadProjectData(projectId) {
    try {
      const Project = await indexedDBServiceInstance.getAll('Project');
      const Milestone = await indexedDBServiceInstance.getAll('Milestone');
      const Mission = await indexedDBServiceInstance.getAll('Mission');
      const TaskAction = await indexedDBServiceInstance.getAll('TaskAction');
      return this.structureData(Project, Milestone, Mission, TaskAction, projectId);
    } catch (error) {
      console.error("讀取專案資料時發生錯誤:", error);
      throw error;
    }
  }

  structureData(Project, Milestone, Mission, TaskAction, projectId) {
    // 計算一組項目的總工時
    const calculateTotalDuration = (items) => {
      return items.reduce((total, item) => total + parseFloat(item.duration || 0), 0).toFixed(1);
    };
    const calculateTaskActionDuration = (items) => {
      return items.reduce((total, item) => total + parseFloat(item.cost || 0), 0).toFixed(1);
    };
    // 計算一組項目的每日工時
    const calculateDailyHours = (items) => {
      const dailyHours = {};
      items.forEach(item => {
        if (item.dailyHours) {
          Object.entries(item.dailyHours).forEach(([date, hours]) => {
            dailyHours[date] = (parseFloat(dailyHours[date] || 0) + parseFloat(hours)).toFixed(1);
          });
        }
      });
      // 確保結果為浮點數格式
      Object.keys(dailyHours).forEach(date => {
        dailyHours[date] = parseFloat(dailyHours[date]);
      });
      return dailyHours;
    };

    // 根據 action 的 duration 生成 dailyHours
    const generateDailyHoursFromActions = (TaskAction) => {
      const dailyHours = {};
      TaskAction.forEach(action => {
        if (action.cost > 0) {
          const startDate = new Date(action.startDate);
          const dateKey = startDate.toISOString().split('T')[0];
          dailyHours[dateKey] = action.cost;
        }
      });
      return dailyHours;
    };

    return Project
      // 過濾出特定 projectId 的專案（如果指定的話）
      .filter(project => !projectId || project.id === projectId)
      // 對每個專案進行處理
      .map(project => {
        // 過濾出屬於該專案的里程碑
        const projectMilestones = Milestone.filter(m => m.parentId === project.id);
        // 更新里程碑
        const updatedMilestones = projectMilestones.map(milestone => {
          // 過濾出屬於該里程碑的任務
          const milestoneTasks = Mission.filter(t => t.parentId === milestone.id);

          // 更新任務
          const updatedTasks = milestoneTasks.map(task => {
            // 過濾出屬於該任務的行動
            const taskActions = TaskAction.filter(a => a.parentId === task.id);

            // 根據行動生成任務的 dailyHours
            const taskDailyHours = generateDailyHoursFromActions(taskActions);

            return {
              ...task,
              tableType: 'Mission',
              parentVersion: `${milestone.version}.`,
              children: taskActions.map(action => ({
                ...action,
                parentVersion: `${milestone.version}.${task.version}.`,
                tableType: 'TaskAction',
                dailyHours: generateDailyHoursFromActions([action]), // 為每個 action 生成 dailyHours
                duration: parseFloat(action.cost) // 確保 duration 是數字格式
              })),
              dailyHours: taskDailyHours,
              duration: calculateTaskActionDuration(taskActions)
            };
          });

          // 根據任務生成里程碑的 dailyHours
          const milestoneDailyHours = calculateDailyHours(updatedTasks);

          return {
            ...milestone,
            tableType: 'Milestone',
            children: updatedTasks,
            dailyHours: milestoneDailyHours,
            duration: calculateTotalDuration(updatedTasks)
          };
        });

        // 根據里程碑生成專案的 dailyHours
        const projectDailyHours = calculateDailyHours(updatedMilestones);

        return {
          ...project,
          tableType: 'Project',
          children: updatedMilestones,
          dailyHours: projectDailyHours,
          duration: calculateTotalDuration(updatedMilestones)
        };
      });
  }

  getBorderColorByType(type) {
    switch (type) {
      case 'Project':
        return '3px solid #3B82F6';
      case 'Milestone':
        return '3px solid #10B981';
      case 'Mission':
        return '3px solid #F59E0B';
      case 'TaskAction':
        return '3px solid #EF4444';  // 修改：為 TaskAction 也添加邊框
      default:
        console.warn(`未知的項目類型: ${type}，使用默認邊框顏色`);
        return '3px solid #9CA3AF';  // 為未知類型提供一個默認顏色
    }
  }
  getColorByLevel(level) {
    switch (level) {
      case 'Project':
        return '#3B82F6';
      case 'Milestone':
        return '#10B981';
      case 'Mission':
        return '#F59E0B';
      case 'TaskAction':
        return '#EF4444';
      default:
        return 'bg-gray-100';
    }
  }

  getBackgroundColorByType(type) {
    switch (type) {
      case 'Project':
        return 'bg-blue-100';
      case 'Milestone':
        return 'bg-green-100';
      case 'Mission':
        return 'bg-yellow-100';
      case 'TaskAction':
        return 'bg-red-100';
      default:
        return 'bg-gray-100';
    }
  }

  getEditIconByLevel(level) {
    switch (level) {
      case 0:
        return '📝';
      case 1:
        return '🛠️';
      case 2:
        return '🔧';
      case 3:
        return '✏️';
      default:
        return '🔍';
    }
  }


  renderGanttBarForMonth(item, monthStart, monthEnd, barHeight, rowHeight) {
    const startDate = new Date(item.startDate);
    const endDate = item.endDate ? new Date(item.endDate) : new Date(); // 如果 endDate 為 null，使用當前日期

    // 將 monthEnd 設置為當月的 23:59:59
    const monthEndDate = new Date(monthEnd);
    monthEndDate.setHours(23, 59, 59, 999);

    // 如果開始日期在當前月份之後，則不渲染
    if (startDate > monthEndDate) {
      return null;
    }
    if (endDate < monthStart) {
      return null;
    }
    const totalDays = (monthEndDate - monthStart) / (1000 * 60 * 60 * 24) + 1;
    const startOffset = Math.max((startDate - monthStart) / (1000 * 60 * 60 * 24), 0);
    const duration = Math.min((endDate - monthStart) / (1000 * 60 * 60 * 24), totalDays - 1) - startOffset + 1;
    let width = (duration / totalDays) * 100;
    let left = (startOffset / totalDays) * 100;

    const actualBarHeight = Math.min(barHeight, rowHeight * 0.8);

    // 動態調整邊框
    let leftBorder = this.getBorderColorByType(item.tableType);
    let rightBorder = this.getBorderColorByType(item.tableType);

    if (startDate < monthStart) {
      left = 0;
      leftBorder = 'none';
    }
    if (!item.endDate || endDate > monthEndDate) {
      rightBorder = 'none';
      width = 100 - left;
    }
    // 根據 left 和 width 調整對齊方式
    const justifyContent = left > 0 ? 'flex-end' : (width < 100 ? 'flex-start' : 'flex-start');

    return (
      <div
        className="absolute overflow-hidden flex items-center"
        style={{
          left: `0%`,
          width: `100%`,
          height: `100%`,
          top: '0',
          backgroundColor: 'transparent',
          border: 'none',
          display: 'flex',
          justifyContent: justifyContent,
        }}
      >
        <div
          style={{
            width: `${width}%`,
            height: `${rowHeight * 0.7}px`,
            backgroundColor: 'transparent',
            borderTop: this.getBorderColorByType(item.tableType),
            borderBottom: this.getBorderColorByType(item.tableType),
            borderLeft: leftBorder,
            borderRight: rightBorder
          }}
        >
          {/* 渲染每日工時條 */}
          {Object.keys(item.dailyHours || {}).map(dateKey => {
            const currentDate = new Date(dateKey);
            if (currentDate >= monthStart && currentDate <= monthEndDate) {
              const dayOffset = (currentDate - monthStart) / (1000 * 60 * 60 * 24);
              const left2 = (dayOffset / totalDays) * 100;
              const hours = item.dailyHours[dateKey];
              const opacity = Math.min(hours / 8, 1);

              if (currentDate >= startDate && currentDate <= endDate) {
                return (
                  <div
                    key={dateKey}
                    style={{
                      position: 'absolute',
                      left: `${left2}%`,
                      width: `${100 / totalDays}%`,
                      height: `${actualBarHeight}px`,
                      backgroundColor: `rgba(0, 0, 255, ${opacity})`,
                      top: '50%',
                      transform: 'translateY(-50%)',
                      border: 'none'
                    }}
                  />
                );
              }
            }
            return null;
          })}
        </div>
      </div>
    );
  };




  findItemByPath(path, data) {
    if (!path) return null;

    const parts = path.split('-');
    const type = parts[0];
    const currentId = parseInt(parts[1], 10);

    const findRecursive = (items) => {
      for (const item of items) {
        if (item.tableType === type && item.id === currentId) {
          return item;
        }
        if (item.children) {
          const result = findRecursive(item.children);
          if (result) {
            return result;
          }
        }
      }
      return null;
    };

    return findRecursive(data);
  }

  calculateChartDates(data) {
    let minDate = null;
    let maxDate = null;

    const parseDate = (dateString) => {
      if (!dateString) return null;
      const parsed = new Date(dateString);
      return isNaN(parsed.getTime()) ? null : parsed;
    };

    const updateMinMaxDates = (item) => {
      const startDate = parseDate(item.startDate);
      const endDate = parseDate(item.endDate);
      if (startDate && (!minDate || startDate < minDate)) {
        minDate = startDate;
      }
      if (endDate && (!maxDate || endDate > maxDate)) {
        maxDate = endDate;
      } else if (!endDate) {
        // 如果 endDate 為 null，使用當前日期
        const currentDate = new Date();
        if (!maxDate || currentDate > maxDate) {
          maxDate = currentDate;
        }
      }

      if (item.children) {
        item.children.forEach(updateMinMaxDates);
      }
    };

    data.forEach(updateMinMaxDates);

    if (minDate && maxDate) {
      // 確保日期範圍至少跨越一天
      if (minDate.getTime() === maxDate.getTime()) {
        maxDate = new Date(maxDate.getTime() + 24 * 60 * 60 * 1000);
      }
      // 將結束日期延長一個月，以確保有足夠的空間顯示進行中的任務
      maxDate = new Date(maxDate.getFullYear(), maxDate.getMonth() + 1, maxDate.getDate());
      const totalChartDays = Math.ceil((maxDate - minDate) / (1000 * 60 * 60 * 24)) + 1;
      return { chartStartDate: minDate, chartEndDate: maxDate, totalChartDays };
    }

    // 如果沒有有效日期，返回基於當前日期的默認範圍
    const today = new Date();
    const oneMonthLater = new Date(today.getFullYear(), today.getMonth() + 1, today.getDate());
    return {
      chartStartDate: today,
      chartEndDate: oneMonthLater,
      totalChartDays: 30
    };
  }

  async saveItem(item) {
    try {
      if (!item.id) {
        // 如果是新項目，生成一個唯一的整數 ID，並加上0到99的亂數
        item.id = Date.now() * 100 + Math.floor(Math.random() * 100);
      }
      if (item.id) {
        await indexedDBServiceInstance.update(item.tableType, item);
      } else {
        await indexedDBServiceInstance.add(item.tableType, item);
      }
    } catch (error) {
      console.error("保存項目時發生錯誤:", error);
      throw error;
    }
  }

  async deleteItem(item) {
    try {
      await indexedDBServiceInstance.delete(item.tableType + 's', item.id);
    } catch (error) {
      console.error("刪除項目時發生錯誤:", error);
      throw error;
    }
  }

}

const ganttChartService = new GanttChartService();
export default ganttChartService;
