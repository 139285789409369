// HistoryView.js
import React, { useState, useEffect, useCallback, useRef } from 'react';
import HistoryDataService from './HistoryDataService';
import HistoryItem from './HistoryItem';
import { getTableTypeName, getChildTableTypeName, getChildTableType } from '../../utils/utils';
const HistoryView = ({
  item,
  type,
  editMode,
  readOnly,
  preVersion,
  access
}) => {
  const [parentItem, setParentItem] = useState(null);
  const [childItems, setChildItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedItems, setExpandedItems] = useState({});
  const containerRef = useRef(null);

  const canAdd = access >= 3 && !readOnly;
  const canEdit = access >= 2 && !readOnly;

  const fetchHistory = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const fetchedData = await HistoryDataService.fetchHistoryItems(item.id, type);

      // 遞歸函數來為每個項目添加評論
      const addCommentsToItems = (items) => {
        return items.map(item => ({
          ...item,
          comment: item.comments || [],
          children: item.children ? addCommentsToItems(item.children) : []
        }));
      };

      const updatedParentItem = {
        ...item,
        tableType: type,
        tableTypeName: getTableTypeName(type),
        ChildtableTypeName: getChildTableTypeName(type),
        ChildtableType: getChildTableType(type),
        access: access,
        children: addCommentsToItems(fetchedData.children),
        comment: fetchedData.comments || []
      };
      setParentItem(updatedParentItem);
      setChildItems(updatedParentItem.children);
    } catch (err) {
      console.error(`Error loading history for ${type} ${item.id}:`, err);
      setError(`載入歷史記錄時發生錯誤: ${err.message}`);
    } finally {
      setLoading(false);
    }
  }, [item, type, access]);

  useEffect(() => {
    fetchHistory();
  }, [fetchHistory]);

  const handleSave = useCallback(async (updatedItem) => {
    try {
      await fetchHistory();
    } catch (err) {
      console.error('保存失敗:', err);
      setError('保存時發生錯誤');
    }
  }, [fetchHistory]);

  const handleUpdateChildItems = useCallback(async (parentId, updatedChildItems) => {
    try {
      if (parentId === parentItem.id) {
        setChildItems(updatedChildItems);
        await fetchHistory();
      }
    } catch (error) {
      console.error('更新子項目時發生錯誤:', error);
      setError('更新子項目時發生錯誤');
    }
  }, [parentItem, fetchHistory]);

  const handleRefresh = useCallback((refreshedItemRef, isExpanded) => {
    try {
      fetchHistory().then(() => {
        if (refreshedItemRef && refreshedItemRef.current) {
          refreshedItemRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        }
      });
    } catch (error) {
      console.error('刷新時發生錯誤:', error);
      setError('刷新時發生錯誤');
    }
  }, [fetchHistory]);

  const handleToggleExpand = useCallback((itemId, isExpanded) => {
    try {
      setExpandedItems(prev => ({ ...prev, [itemId]: isExpanded }));
    } catch (error) {
      console.error('展開/收起項目時發生錯誤:', error);
      setError('展開/收起項目時發生錯誤');
    }
  }, []);

  if (loading) {
    return <div className="text-center py-4">載入中...</div>;
  }

  if (error) {
    return <div className="text-red-500 text-center py-4">{error}</div>;
  }

  return (
    <div className="max-w-2xl mx-auto" ref={containerRef}>
      {parentItem && (
        <HistoryItem
          item={{ ...parentItem, preVersion: preVersion }}
          onSave={handleSave}
          childItemType={getChildTableType(type)}
          readOnly={readOnly}
          editMode={editMode}
          setError={setError}
          containerRef={containerRef}
          access={access}
          onUpdateChildItems={handleUpdateChildItems}
          isParentItem={true}
          canEdit={canEdit}
          canAdd={canAdd}
          onRefresh={handleRefresh}
          isExpanded={expandedItems[parentItem.id]}
          onToggleExpand={handleToggleExpand}
        />
      )}
    </div>
  );
};

export default HistoryView;