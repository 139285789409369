import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import {
  useTable,
  useExpanded,
  useSortBy,
  useResizeColumns,
  useBlockLayout,
} from "react-table";
import { VariableSizeList as List } from "react-window";
import {
  TextField,
  Autocomplete,
  Chip,
  Paper,
  TableContainer,
  Card,
  CardContent,
  Typography,
  IconButton,
  Tooltip,
  Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import itemService from "../../services/db/ItemService";
import ItemEditModal from "../common/ItemEditModal";
import {
  formatDateTime,
  formatDate,
  formatTime,
  getCurrentLocalDateTime,
  getRelativeTime,
} from "../../utils/dateUtils";
import {
  getChildTableType,
  getTableTypeName,
  getCostColor,
  getColorStyles,
  priorityOrder,
} from "../../utils/utils";
import DropdownMenu from "../common/DropdownMenu_mui";
import { getUser } from "../../services/authService";
import UpdateIcon from "@mui/icons-material/Update";
const StyledTableRow = styled("div")(({ theme }) => ({
  display: "flex",
  "&:hover": {
    backgroundColor: "#e3f2fd",
    "& > div": {
      backgroundColor: "#e3f2fd",
    },
  },
}));

const StyledTableCell = styled("div")(({ theme, isHeader, align }) => ({
  padding: theme.spacing(1),
  backgroundColor: isHeader ? "#F9FAFB" : "#FFFFFF",
  color: "#333333",
  position: "relative",
  borderRight: "1px solid #e0e0e0",
  "&:last-child": {
    borderRight: "none",
  },
  display: "flex",
  alignItems: "center",
  justifyContent:
    align === "right"
      ? "flex-end"
      : align === "center"
      ? "center"
      : "flex-start",
  textAlign: align,
  overflow: "hidden",
  transition: "background-color 0.2s",
}));

const CellContent = styled("div")({
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  width: "100%",
});

const NameCell = styled("div")(({ theme, depth }) => ({
  display: "flex",
  alignItems: "center",
  paddingLeft: theme.spacing(2 + depth * 2),
  width: "100%",
  overflow: "hidden",
}));

const NameText = styled("span")({
  flex: 1,
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
});

const ActionButton = styled(IconButton)({
  padding: 4,
  marginLeft: "auto",
});

const TableWrapper = styled("div")({
  position: "relative",
  height: "calc(100vh - 200px)",
  overflow: "hidden",
});

const TableHeader = styled("div")({
  paddingRight: "17px", // 假設滾動條寬度為17px，根據實際情況調整
  overflow: "hidden",
});

const TableBody = styled("div")({
  height: "calc(100% - 40px)", // 假設表頭高度為40px，根據實際情況調整
  overflow: "auto",

  // 自定義滾動條樣式
  "&::-webkit-scrollbar": {
    width: "10px",
  },
  "&::-webkit-scrollbar-track": {
    background: "#f1f1f1",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#888",
    borderRadius: "5px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background: "#555",
  },

  // 針對 Firefox
  scrollbarWidth: "thin",
  scrollbarColor: "#888 #f1f1f1",
});
const StyledIconButton = styled(IconButton)(({ theme }) => ({
  padding: 4,
  backgroundColor: "transparent",
  color: "#666666",
  "&:hover": {
    backgroundColor: "#E0E0E0",
  },
}));

const StyledChip = styled(Chip)(({ colorstyle }) => ({
  backgroundColor: colorstyle.backgroundColor,
  color: colorstyle.color,
  "& .MuiChip-label": {
    color: colorstyle.color,
  },
}));

const FilterContainer = styled("div")({
  display: "flex",
  gap: "10px",
  flexWrap: "wrap",
  marginBottom: "20px",
});

const incrementalUpdate = (currentData, newData) => {
  const updateItem = (currentItem, newItem) => {
    if (new Date(newItem.updatedAt) > new Date(currentItem.updatedAt)) {
      return {
        ...currentItem,
        ...newItem,
        subRows: currentItem.subRows,
      };
    }
    return currentItem;
  };

  const updateTree = (currentItems, newItems) => {
    const newItemMap = new Map(newItems.map((item) => [item.id, item]));
    const updatedItems = currentItems.map((currentItem) => {
      const newItem = newItemMap.get(currentItem.id);
      if (newItem) {
        const updatedItem = updateItem(currentItem, newItem,"");
        if (updatedItem.subRows && newItem.subRows) {
          updatedItem.subRows = updateTree(
            updatedItem.subRows,
            newItem.subRows
          );
        }
        return updatedItem;
      }
      return currentItem;
    });

    newItems.forEach((newItem) => {
      if (!currentItems.some((item) => item.id === newItem.id)) {
        updatedItems.push(newItem);
      }
    });

    return updatedItems;
  };
  const result = updateTree(currentData, newData);
  return result;
};

const ProjectTreeList = (refreshTrigger) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filters, setFilters] = useState({
    name: "",
    status: "",
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const prevRefreshTriggerRef = useRef();
  const [dataChanged, setDataChanged] = useState(false);
  const [parentContainerWidth, setParentContainerWidth] = useState(
    window.innerWidth
  );
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [types, setTypes] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const currentUser = useMemo(() => getUser(), []);

  const buildTreeStructure = useCallback(
    (projects, milestones, missions, actions, users, types, statusOptions) => {
      const userMap = new Map(users.map((user) => [user.accountId, user]));
      const typeMap = new Map(types.map((type) => [type.typeId, type]));
      const statusMap = new Map(statusOptions.map((s) => [s.statusId, s]));
      const priorityMap = new Map(priorityOrder.map((p) => [p.id, p]));

      const getVersionString = (item, parent, grandparent) => {
        if (item.tableType === "Project") return "";
        if (item.tableType === "Milestone") return `V${item.version ?? "0"}`;
        if (item.tableType === "Mission")
          return `V${parent?.version ?? "0"}.${item.version ?? "0"}`;
        if (item.tableType === "TaskAction")
          return `V${grandparent?.version ?? "0"}.${parent?.version ?? "0"}.${
            item.version ?? "0"
          }`;
        return "";
      };

      const processedActions = actions.map((action) => ({
        ...action,
        typeItem: typeMap.get(action.typeId),
        statusItem: null,
        assigneeItem: userMap.get(action.accountId),
        priorityItem: priorityMap.get(action.priority),
        tableType: "TaskAction",
        versionString: "",
      }));

      const actionMap = new Map();
      processedActions.forEach((action) => {
        if (!actionMap.has(action.parentId)) {
          actionMap.set(action.parentId, []);
        }
        actionMap.get(action.parentId).push(action);
      });

      const processedMissions = missions.map((mission) => ({
        ...mission,
        tableType: "Mission",
        typeItem: typeMap.get(mission.typeId),
        statusItem: statusMap.get(mission.statusId),
        assigneeItem: userMap.get(mission.accountId),
        priorityItem: priorityMap.get(mission.priority),
        subRows: actionMap.get(mission.id) || [],
        versionString: "",
      }));

      const missionMap = new Map();
      processedMissions.forEach((mission) => {
        if (!missionMap.has(mission.parentId)) {
          missionMap.set(mission.parentId, []);
        }
        missionMap.get(mission.parentId).push(mission);
      });

      const processedMilestones = milestones.map((milestone) => ({
        ...milestone,
        tableType: "Milestone",
        typeItem: typeMap.get(milestone.typeId),
        statusItem: statusMap.get(milestone.statusId),
        assigneeItem: userMap.get(milestone.accountId),
        priorityItem: priorityMap.get(milestone.priority),
        subRows: missionMap.get(milestone.id) || [],
        versionString: getVersionString({
          ...milestone,
          tableType: "Milestone",
        }),
      }));

      processedMilestones.forEach((milestone) => {
        milestone.subRows.forEach((mission) => {
          mission.versionString = getVersionString(mission, milestone);
          mission.subRows.forEach((action) => {
            action.versionString = getVersionString(action, mission, milestone);
          });
        });
      });

      const milestoneMap = new Map();
      processedMilestones.forEach((milestone) => {
        if (!milestoneMap.has(milestone.parentId)) {
          milestoneMap.set(milestone.parentId, []);
        }
        milestoneMap.get(milestone.parentId).push(milestone);
      });

      const treeData = projects.map((project) => ({
        ...project,
        tableType: "Project",
        typeItem: typeMap.get(project.typeId),
        statusItem: statusMap.get(project.statusId),
        assigneeItem: userMap.get(project.accountId),
        priorityItem: priorityMap.get(project.priority),
        subRows: milestoneMap.get(project.id) || [],
        versionString: "",
      }));
      return treeData;
    },
    []
  ); // 如果這個函數依賴於任何外部變量，請將它們添加到依賴數組中

  const loadData = useCallback(async () => {
    setLoading(true);
    try {
      const [
        projects,
        milestones,
        missions,
        actions,
        users,
        types,
        statusOptions,
      ] = await Promise.all([
        itemService.fetch("Project"),
        itemService.fetch("Milestone"),
        itemService.fetch("Mission"),
        itemService.fetch("TaskAction"),
        itemService.fetch("User"),
        itemService.fetch("Type"),
        itemService.fetch("StatusOption"),
      ]);

      setTypes(
        types.filter((item) => {
          return item.typeCategory === "Project";
        }) || []
      );
      setStatusOptions(
        statusOptions.filter((item) => {
          return item.appliesTo === "Project";
        }) || []
      );
      const treeData = buildTreeStructure(
        projects,
        milestones,
        missions,
        actions,
        users,
        types,
        statusOptions
      );

      setData(treeData || []);
    } catch (err) {
      console.error("加載數據時出錯:", err);
      setError("加載數據失敗。請嘗試刷新頁面。");
    } finally {
      setLoading(false);
    }
  }, [buildTreeStructure]);

  const fetchNewData = useCallback(async () => {
    try {
      const [
        projects,
        milestones,
        missions,
        actions,
        users,
        types,
        statusOptions,
      ] = await Promise.all([
        itemService.fetch("Project"),
        itemService.fetch("Milestone"),
        itemService.fetch("Mission"),
        itemService.fetch("TaskAction"),
        itemService.fetch("User"),
        itemService.fetch("Type"),
        itemService.fetch("StatusOption"),
      ]);
      // 處理新數據，構建樹狀結構
      const processedNewData = buildTreeStructure(
        projects,
        milestones,
        missions,
        actions,
        users,
        types,
        statusOptions
      );
      return processedNewData;
    } catch (err) {
      console.error("獲取新數據時出錯:", err);
      setError("獲取新數據失敗。");
      return null;
    }
  }, [buildTreeStructure, setError]); // 添加所有使用到的函數和 state 設置器

  const handleNewData = useCallback(async () => {
    try {
      const newData = await fetchNewData();
      if (newData) {
        setData((prevData) => incrementalUpdate(prevData, newData));
      }
    } catch (error) {
      console.error("更新失敗:", error);
      setError("數據更新錯誤");
    }
  }, [fetchNewData]);

  useEffect(() => {
    if (refreshTrigger !== prevRefreshTriggerRef.current) {
      handleNewData();
      setDataChanged(false);
      prevRefreshTriggerRef.current = refreshTrigger;
    }
  }, [refreshTrigger, loadData, handleNewData]);

  useEffect(() => {
    if (dataChanged) {
      loadData();
      setDataChanged(false);
    }
  }, [dataChanged, loadData]);

  useEffect(() => {
    loadData();
    const handleResize = () => setParentContainerWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    const handleDataUpdated = () => {
      handleNewData();
    };
    window.addEventListener("dataUpdated", handleDataUpdated);

    return () => {
      window.removeEventListener("resize", handleResize);
      // 移除自定義事件監聽器
      window.removeEventListener("dataUpdated", handleDataUpdated);
    };
  }, [loadData, handleNewData]);
  const handleOpenMenu = useCallback((event, item) => {
    setAnchorEl(event.currentTarget);
    setIsEditModalOpen(false);
    setSelectedItem(item);
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "名稱",
        accessor: "name",
        width: 300, // Increased width to accommodate the action button
        align: "left",
        tips: ({ value }) => value,
        Cell: ({ value, row }) => (
          <Tooltip title={value} placement="bottom">
            <NameCell depth={row.depth}>
              {row.canExpand ? (
                <StyledIconButton {...row.getToggleRowExpandedProps()}>
                  {row.isExpanded ? (
                    <ExpandMoreIcon fontSize="small" />
                  ) : (
                    <ChevronRightIcon fontSize="small" />
                  )}
                </StyledIconButton>
              ) : (
                <span style={{ width: 24 }} />
              )}
              <NameText>{value}</NameText>
              <ActionButton
                onClick={(event) => handleOpenMenu(event, row.original)}
                size="small"
              >
                <MoreVertIcon />
              </ActionButton>
            </NameCell>
          </Tooltip>
        ),
      },
      {
        Header: "版本",
        accessor: "versionString",
        width: 80,
        align: "left",
        tips: ({ value }) => value,
      },
      {
        Header: "類型",
        accessor: "typeItem",
        width: 120,
        align: "center",
        Cell: ({ value, row }) => (
          <StyledChip
            label={value?.typeName}
            size="small"
            colorstyle={
              row.original.tableType === "Project"
                ? getColorStyles("bg-blue-200 text-gray-800")
                : row.original.tableType === "Milestone"
                ? getColorStyles("bg-green-200 text-gray-800")
                : row.original.tableType === "Mission"
                ? getColorStyles("bg-orange-200 text-gray-800")
                : getColorStyles("bg-yellow-200 text-gray-800")
            }
          />
        ),
        tips: ({ value }) => value?.typeName,
      },
      {
        Header: "負責人",
        accessor: "assigneeItem",
        width: 120,
        align: "center",
        Cell: ({ value }) => (
          <StyledChip
            label={value?.username}
            size="small"
            colorstyle={
              currentUser.userId === value?.accountId
                ? getColorStyles("bg-yellow-200 text-gray-800")
                : getColorStyles("bg-gray-200 text-gray-800")
            }
          />
        ),
        tips: ({ value }) => value?.username,
      },
      {
        Header: "狀態",
        accessor: "statusItem",
        width: 120,
        align: "center",
        Cell: ({ value, row }) => {
          if (row.original.tableType === "TaskAction") return null;
          return (
            <StyledChip
              label={value?.statusName}
              size="small"
              colorstyle={getColorStyles(value?.color)}
            />
          );
        },
        tips: ({ value }) => value?.statusName,
      },
      {
        Header: "優先",
        accessor: "priorityItem",
        width: 100,
        align: "center",
        sortType: (rowA, rowB) => {
          const priorityA = rowA.original.priority || 0;
          const priorityB = rowB.original.priority || 0;
          return priorityA - priorityB;
        },
        Cell: ({ value, row }) => {
          if (row.original.tableType === "TaskAction") return null;
          return (
            <StyledChip
              label={value?.label}
              size="small"
              colorstyle={getColorStyles(value?.color)}
            />
          );
        },
        tips: ({ value }) => value?.label,
      },
      {
        Header: "期間",
        accessor: "startDate",
        width: 200,
        minWidth: 120,
        align: "left",
        Cell: ({ value, row }) => {
          let formattedValue;
          if (row.original.tableType === "TaskAction") {
            formattedValue = `${formatDateTime(value)}~${formatTime(
              row.original.endDate
            )}`;
          } else {
            formattedValue = `${formatDate(value)}~${formatDate(
              row.original.endDate
            )}`;
          }

          return <CellContent>{formattedValue}</CellContent>;
        },
        tips: ({ value, row }) => {
          if (!row) {
            return "";
          }
          let tooltipValue;
          if (row.tableType === "TaskAction") {
            tooltipValue = `${formatDateTime(value)}~${formatTime(
              row.endDate
            )}`;
          } else {
            tooltipValue = `${formatDate(value)}~${formatDate(row.endDate)}`;
          }

          return tooltipValue;
        },
      },
      {
        Header: "花費",
        accessor: "cost",
        width: 120,
        minWidth: 100,
        align: "right",
        Cell: ({ value, row }) => {
          if (row.original.tableType === "TaskAction") {
            return (
              <Tooltip
                title={`${new Intl.NumberFormat("en-US").format(value)}`}
                placement="bottom"
              >
                <CellContent>
                  {new Intl.NumberFormat("en-US").format(value)}
                </CellContent>
              </Tooltip>
            );
          } else {
            const cost = row.original.cost;
            const budget = row.original.budget;
            const color = getCostColor(cost, budget);
            return (
              <Tooltip
                title={`花費: ${new Intl.NumberFormat("en-US").format(
                  cost
                )}, 預算: ${new Intl.NumberFormat("en-US").format(budget)}`}
                placement="bottom"
              >
                <CellContent style={{ color }}>
                  {`${new Intl.NumberFormat("en-US").format(
                    cost
                  )}/${new Intl.NumberFormat("en-US").format(budget)}`}
                </CellContent>
              </Tooltip>
            );
          }
        },
      },
      {
        Header: "最後更新",
        accessor: "updatedAt",
        width: 150,
        minWidth: 120,
        align: "right",
        Cell: ({ value }) => {
          const relativeTime = getRelativeTime(value);
          let formattedValue;
          formattedValue = `${formatDateTime(value)}`;
          return (
            <Tooltip title={formattedValue} placement="bottom">
              <CellContent
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <UpdateIcon style={{ marginRight: "5px", fontSize: "16px" }} />
                {relativeTime}
              </CellContent>
            </Tooltip>
          );
        },
      },
    ],
    [handleOpenMenu, currentUser]
  );

  const filteredData = useMemo(() => {
    if (!Array.isArray(data)) {
      console.error("data is not an array:", data);
      return [];
    }
    return data.filter((item) => {
      const nameMatch = item.name
        .toLowerCase()
        .includes((filters.name || "").toLowerCase());
      const typeMatch =
        !filters.types ||
        filters.types.length === 0 ||
        filters.types.some((type) => type.typeId === item.typeId);
      const statusMatch =
        !filters.statuses ||
        filters.statuses.length === 0 ||
        filters.statuses.some(
          (status) =>
            status.statusId === item.statusId ||
            status.statusName === item.statusName
        );
      return nameMatch && typeMatch && statusMatch;
    });
  }, [data, filters]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    totalColumnsWidth,
    state: { columnResizing },
  } = useTable(
    {
      columns,
      data: filteredData,
      autoResetExpanded: false,
      autoResetPage: false,
      disableSortRemove: true,
      defaultCanSort: false,
    },
    useBlockLayout,
    useResizeColumns,
    useSortBy,
    useExpanded
  );

  const handleHeaderClick = useCallback(
    (column) => {
      if (column.canSort && !columnResizing.isResizingColumn) {
        column.toggleSortBy();
      }
    },
    [columnResizing.isResizingColumn]
  );

  const handleFilterChange = (key, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
  };

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleEdit = useCallback(() => {
    setIsEditModalOpen(true);
    handleCloseMenu();
  }, [handleCloseMenu]);

  const handleDelete = useCallback(
    async (item) => {
      if (window.confirm(`確定要刪除 ${item.name} 嗎？`)) {
        try {
          await itemService.deleteItem(item.tableType, item.id);
          await loadData();
        } catch (error) {
          console.error("刪除失敗:", error);
          setError("刪除數據時發生錯誤");
        }
      }
      handleCloseMenu();
    },
    [loadData, handleCloseMenu]
  );

  const handleNew = useCallback(
    (parentItem) => {
      if (parentItem && parentItem.cost >= parentItem.budget) {
        alert(
          `${getTableTypeName(parentItem.tableType)}預算不足：${
            parentItem.name
          }的成本已超出預算。`
        );
        return;
      }
      const newItem = {
        tableType: parentItem
          ? getChildTableType(parentItem.tableType)
          : "Project",
        name: `新${getTableTypeName(
          parentItem ? getChildTableType(parentItem.tableType) : "Project"
        )}`,
        startDate: getCurrentLocalDateTime(),
        endDate: null,
        parentId: parentItem ? parentItem.id : null,
        parent: parentItem,
      };
      setSelectedItem(newItem);
      setIsEditModalOpen(true);
      handleCloseMenu();
    },
    [handleCloseMenu]
  );

  const handleSave = useCallback(() => {
    try {
      // 保存邏輯...
      setIsEditModalOpen(false);
      //   handleNewData(); // 調用增量更新
    } catch (error) {
      console.error("保存失敗:", error);
      setError("數據保存錯誤");
    }
  }, []);

  const handleCloseModal = useCallback(() => {
    setIsEditModalOpen(false);
    setSelectedItem(null);
  }, []);

  const rowRenderer = useCallback(
    ({ index, style }) => {
      const row = rows[index];
      prepareRow(row);
      return (
        <StyledTableRow {...row.getRowProps({ style })} style={{ ...style }}>
          {row.cells.map((cell) => {
            const column = cell.column;
            const tooltipContent = column.tips
              ? column.tips({ value: cell.value, row: row.original })
              : "";

            return (
              <StyledTableCell
                key={cell.column.id}
                style={{ width: cell.column.width }}
                align={cell.column.align}
              >
                <Tooltip title={tooltipContent} placement="bottom">
                  <div style={{ width: "100%" }}>{cell.render("Cell")}</div>
                </Tooltip>
              </StyledTableCell>
            );
          })}
        </StyledTableRow>
      );
    },
    [prepareRow, rows]
  );

  const getItemSize = (index) => 35; // 假設每行高度為 35px，您可以根據實際情況調整

  if (loading) {
    return (
      <Typography variant="h6" align="center">
        加載中...
      </Typography>
    );
  }

  if (error) {
    return (
      <Typography variant="h6" align="center" color="error">
        錯誤: {error}
      </Typography>
    );
  }

  return (
    <Card
      elevation={3}
      style={{
        margin: "20px auto",
        width: "100%",
        backgroundColor: "#FFFFFF",
        color: "#333333",
      }}
    >
      <CardContent>
        <FilterContainer>
          <TextField
            label="名稱篩選"
            value={filters.name}
            onChange={(e) => handleFilterChange("name", e.target.value)}
            variant="outlined"
            size="small"
            style={{ flexGrow: 1 }}
          />
          <Autocomplete
            multiple
            options={types}
            getOptionLabel={(option) => option.typeName}
            value={filters.types}
            onChange={(_, newValue) => handleFilterChange("types", newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="類型"
                variant="outlined"
                size="small"
              />
            )}
            renderOption={(props, option) => (
              <li {...props}>
                <StyledChip
                  label={option.typeName}
                  size="small"
                  colorstyle={getColorStyles("bg-blue-200 text-blue-800")}
                  style={{ margin: "2px" }}
                />
              </li>
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <StyledChip
                  key={index}
                  label={option.typeName}
                  size="small"
                  colorstyle={getColorStyles("bg-blue-200 text-blue-800")}
                  {...getTagProps({ index })}
                />
              ))
            }
            style={{ minWidth: 200 }}
          />

          <Autocomplete
            multiple
            options={statusOptions}
            getOptionLabel={(option) => option.statusName}
            value={filters.statuses}
            onChange={(_, newValue) => handleFilterChange("statuses", newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="狀態"
                variant="outlined"
                size="small"
              />
            )}
            renderOption={(props, option) => (
              <li {...props}>
                <StyledChip
                  label={option.statusName}
                  size="small"
                  colorstyle={getColorStyles(option.color)}
                  style={{ margin: "2px" }}
                />
              </li>
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <StyledChip
                  key={index}
                  label={option.statusName}
                  size="small"
                  colorstyle={getColorStyles(option.color)}
                  {...getTagProps({ index })}
                />
              ))
            }
            style={{ minWidth: 200 }}
          />
          <Button
            variant="contained"
            onClick={() => handleNew()}
            style={{ backgroundColor: "#007AFF", color: "#FFFFFF" }}
          >
            新增專案
          </Button>
        </FilterContainer>
        <TableContainer
          component={Paper}
          style={{ width: "100%", backgroundColor: "#FFFFFF" }}
        >
          <TableWrapper>
            <TableHeader>
              <div
                {...getTableProps()}
                style={{ display: "inline-block", minWidth: "100%" }}
              >
                {headerGroups.map((headerGroup) => (
                  <div
                    {...headerGroup.getHeaderGroupProps()}
                    style={{ display: "flex" }}
                  >
                    {headerGroup.headers.map((column) => (
                      <StyledTableCell
                        key={column.id}
                        {...column.getHeaderProps()}
                        isHeader={true}
                        style={{
                          width: column.width,
                          position: "relative",
                        }}
                        onClick={() => handleHeaderClick(column)}
                      >
                        {column.render("Header")}
                        <span style={{ marginLeft: "4px" }}>
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <ArrowDownwardIcon fontSize="small" />
                            ) : (
                              <ArrowUpwardIcon fontSize="small" />
                            )
                          ) : (
                            ""
                          )}
                        </span>
                        {column.canResize && (
                          <div
                            {...column.getResizerProps()}
                            style={{
                              position: "absolute",
                              right: 0,
                              top: 0,
                              height: "100%",
                              width: "10px",
                              cursor: "col-resize",
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                            }}
                          />
                        )}
                      </StyledTableCell>
                    ))}
                  </div>
                ))}
              </div>
            </TableHeader>
            <TableBody>
              <div {...getTableBodyProps()}>
                <List
                  height={window.innerHeight - 240} // 調整高度以適應新結構
                  itemCount={rows.length}
                  itemSize={getItemSize}
                  width={totalColumnsWidth}
                >
                  {rowRenderer}
                </List>
              </div>
            </TableBody>
          </TableWrapper>
        </TableContainer>
      </CardContent>
      <DropdownMenu
        isOpen={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        anchorEl={anchorEl}
        currentItem={selectedItem}
        onEdit={handleEdit}
        onDelete={handleDelete}
        onNew={handleNew}
      />

      {isEditModalOpen && selectedItem && (
        <ItemEditModal
          currentItem={selectedItem}
          ItemEditComponent={selectedItem.tableType}
          editMode="popup"
          readOnly={false}
          tableType={selectedItem.tableType}
          parentId={selectedItem.parentId}
          setItemList={handleNewData}
          setErrorMessage={setError}
          parentContainerWidth={parentContainerWidth}
          onSave={handleSave}
          onClose={handleCloseModal}
        />
      )}
    </Card>
  );
};

export default ProjectTreeList;
