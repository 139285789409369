// services/trackingService.js
import { getUser } from "./authService";
import itemService from "./db/ItemService";
let lastPageViewTime = 0;

export const isPageRefresh = () => {
  const currentTime = new Date().getTime();
  const isRefresh = currentTime - lastPageViewTime < 1000;
  lastPageViewTime = currentTime;
  return isRefresh;
};

export const trackPageView = (path, isRefresh = false) => {
  const data = {
    type: isRefresh ? 'pageRefresh' : 'pageView',
    path,
    userId: getUser().userId
  };
  itemService.createItem("Tracking",data);
};

export const trackPageExit = (path, duration) => {
  const data = {
    type: 'pageExit',
    path,
    userId: getUser().userId,
    duration:(duration/1000).toFixed(1)
  };
  //itemService.createItem("Tracking",data);
};