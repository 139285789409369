
export const GENERAL_STATUS_OPTIONS = [
    { value: '籌備', color: 'bg-gray-200 text-gray-800', icon: '📝' },
    { value: '待執行', color: 'bg-blue-200 text-blue-800', icon: '📌' },
    { value: '進行中', color: 'bg-yellow-200 text-yellow-800', icon: '🚀' },
    { value: '待結案', color: 'bg-green-200 text-green-800', icon: '✅' },
    { value: '取消', color: 'bg-red-200 text-red-800', icon: '❌' },
    { value: '結案', color: 'bg-purple-200 text-purple-800', icon: '🏆' }
  ];
  
  export const ACTION_STATUS_OPTIONS = [
    { value: '文件', color: 'bg-gray-200 text-gray-800', icon: '📄' },
    { value: '開會', color: 'bg-yellow-200 text-yellow-800', icon: '👥' },
    { value: '測試', color: 'bg-green-200 text-green-800', icon: '🧪' },
    { value: '設計', color: 'bg-red-200 text-red-800', icon: '🎨' },
    { value: '除錯', color: 'bg-red-200 text-red-800', icon: '🐛' }
  ];
  
  export const ITEM_TYPES = [
    { value: 'Project', label: '專案' },
    { value: 'Milestone', label: '里程碑' },
    { value: 'Mission', label: '任務' },


   // { value: 'TaskAction', label: '行動' }
  ];
  
  export const MIN_COLUMN_WIDTH = 200;
  
  // 可以根據需要添加更多常量