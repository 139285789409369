import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import ProjectEdit from '../Edit/ProjectEdit';
import MilestoneEdit from '../Edit/MilestoneEdit';
import MissionEdit from '../Edit/MissionEdit';
import ActionEdit from '../Edit/ActionEdit';
import StatusSelector from '../common/Selector/StatusSelector';
import ganttChartService from '../../services/GanttChartService';
import ResizablePopup from '../common/ResizablePopup';
import ResizableDrawer from '../common/ResizableDrawer';
import indexedDBServiceInstance from '../../services/db/IndexedDBService';

const DynamicGanttChart = ({
  projectId,
  editMode,
  initialNameColumnWidth = 25,
  initialDurationColumnWidth = 8,
  initialStatusColumnWidth = 8,
  rowHeight = 40,
  barHeight = 20
}) => {
  const [data, setData] = useState([]);
  const [expandedItems, setExpandedItems] = useState({});
  const [editingItem, setEditingItem] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  const [tableWidth, setTableWidth] = useState(50);
  const [currentEditMode, setCurrentEditMode] = useState(editMode);
  const [nameColumnWidth, setNameColumnWidth] = useState(initialNameColumnWidth);
  const [durationColumnWidth, setDurationColumnWidth] = useState(initialDurationColumnWidth);
  const [statusColumnWidth, setStatusColumnWidth] = useState(initialStatusColumnWidth);
  const [maxRowHeight, setMaxRowHeight] = useState(rowHeight);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [highlightedRowId, setHighlightedRowId] = useState(null);

  const leftFirstRowRef = useRef(null);
  const rightFirstRowRef = useRef(null);
  const dropdownRef = useRef(null);
  const leftTableBodyRef = useRef(null);
  const rightTableBodyRef = useRef(null);
  const rightTableContainerRef = useRef(null);
  const leftTableRef = useRef(null);
  const rightTableRef = useRef(null);
  const monthRefs = useRef({});

  useEffect(() => {
    setCurrentEditMode(editMode);
  }, [editMode]);

  const loadData = useCallback(async () => {
    setLoading(true);
    try {
      const structuredData = await ganttChartService.loadProjectData(projectId);
      setData(structuredData);
    } catch (error) {
      console.error('資料載入失敗:', error);
      setError('資料載入錯誤');
    } finally {
      setLoading(false);
    }
  }, [projectId]);

  useEffect(() => {
    loadData();

    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setActiveDropdown(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [loadData]);

  const syncRowHeights = useCallback(() => {
    if (leftFirstRowRef.current && rightFirstRowRef.current) {
      const leftHeight = leftFirstRowRef.current.offsetHeight;
      const rightHeight = rightFirstRowRef.current.offsetHeight;
      const newMaxHeight = Math.max(leftHeight, rightHeight, rowHeight);
      setMaxRowHeight(newMaxHeight);
    }
  }, [rowHeight]);

  useEffect(() => {
    requestAnimationFrame(syncRowHeights);
  }, [data, syncRowHeights]);

  const { chartStartDate, chartEndDate } = useMemo(() => {
    return ganttChartService.calculateChartDates(data);
  }, [data]);

  const isExpanded = useCallback((type, id) => {
    return !!expandedItems[`${type}-${id}`];
  }, [expandedItems]);

  const toggleExpand = useCallback((type, id) => {
    setExpandedItems(prev => ({
      ...prev,
      [`${type}-${id}`]: !prev[`${type}-${id}`]
    }));
  }, []);

  const handleEdit = useCallback((item) => {
    setEditingItem(item);
    setActiveDropdown(null);
    setCurrentEditMode(editMode);
  }, [editMode]);

  const handleSave = async (updatedItem) => {
    try {
      setEditingItem(null);
      loadData();
    } catch (error) {
      console.error('儲存失敗:', error);
      setError('資料儲存錯誤: ' + error.message);
    }
  };

  const handleCancel = () => {
    setEditingItem(null);
  };

  const handleColumnResize = useCallback((e, setter) => {
    e.preventDefault();
    const startX = e.pageX;
    const startWidth = parseFloat(e.target.parentNode.style.width);
    const scale = 5;

    const handleMouseMove = (moveEvent) => {
      const difference = (moveEvent.pageX - startX) / scale;
      setter(Math.max(5, startWidth + difference));
      requestAnimationFrame(syncRowHeights);
    };

    const handleMouseUp = () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  }, [syncRowHeights]);

  const findItemById = useCallback((id, items) => {
    for (const item of items) {
      if (`${item.tableType}-${item.id}` === id) {
        return item;
      }
      if (item.children) {
        const found = findItemById(id, item.children);
        if (found) return found;
      }
    }
    return null;
  }, []);

  const scrollToItemStartDate = useCallback((item) => {
    if (item && item.startDate && rightTableContainerRef.current) {
      const startDate = new Date(item.startDate);
      const monthKey = `${startDate.getFullYear()}-${(startDate.getMonth() + 1).toString().padStart(2, '0')}`;
      const monthElement = monthRefs.current[monthKey];
      if (monthElement) {
        setTimeout(() => {
          const containerRect = rightTableContainerRef.current.getBoundingClientRect();
          const monthRect = monthElement.getBoundingClientRect();
          const scrollLeft = monthRect.left - containerRect.left + rightTableContainerRef.current.scrollLeft;
          
          rightTableContainerRef.current.scrollTo({
            left: Math.max(0, Math.min(scrollLeft, rightTableContainerRef.current.scrollWidth - rightTableContainerRef.current.clientWidth)),
          });
        }, 0);
      } 
    }
  }, []);

  useEffect(() => {
    if (selectedItemId) {
      const selectedItem = findItemById(selectedItemId, data);
      if (selectedItem) {
        scrollToItemStartDate(selectedItem);
      }
    }
  }, [selectedItemId, data, scrollToItemStartDate, findItemById]);

  const handleRowClick = useCallback((item) => {
    setSelectedItemId(`${item.tableType}-${item.id}`);
    setHighlightedRowId(`${item.tableType}-${item.id}`);
  }, []);

  const renderLeftTableHeader = useCallback(() => {
    return (
      <tr className="sticky top-0 bg-gray-100 z-10" style={{ height: `${maxRowHeight}px` }}>
        <th className="px-4 py-2 border-r text-center" style={{ width: `${nameColumnWidth}%`, position: 'relative' }}>
          名稱
          <span
            className="resize-handle"
            style={{ cursor: 'ew-resize', position: 'absolute', right: 0, top: 0, bottom: 0, width: '5px' }}
            onMouseDown={(e) => handleColumnResize(e, setNameColumnWidth)}
          />
        </th>
        <th className="border-r border-gray-300" style={{ width: `${durationColumnWidth}%`, position: 'relative' }}>
          工時
          <span
            className="resize-handle"
            style={{ cursor: 'ew-resize', position: 'absolute', right: 0, top: 0, bottom: 0, width: '5px' }}
            onMouseDown={(e) => handleColumnResize(e, setDurationColumnWidth)}
          />
        </th>
        <th className="border-r border-gray-300" style={{ width: `${statusColumnWidth}%`, position: 'relative', zIndex: 1 }}>
          狀態
          <span
            className="resize-handle"
            style={{ cursor: 'ew-resize', position: 'absolute', right: 0, top: 0, bottom: 0, width: '5px' }}
            onMouseDown={(e) => handleColumnResize(e, setStatusColumnWidth)}
          />
        </th>
      </tr>
    );
  }, [nameColumnWidth, durationColumnWidth, statusColumnWidth, maxRowHeight, handleColumnResize]);

  const renderRightTableHeader = useCallback(() => {
    const months = [];
    const startDate = new Date(chartStartDate);
    const endDate = new Date(chartEndDate);

    while (startDate <= endDate) {
      const monthKey = `${startDate.getFullYear()}-${(startDate.getMonth() + 1).toString().padStart(2, '0')}`;
      months.push({ date: new Date(startDate), key: monthKey });
      startDate.setMonth(startDate.getMonth() + 1);
    }

    return (
      <tr className="sticky top-0 bg-gray-100 z-10" style={{ height: `${maxRowHeight}px` }}>
        {months.map(({ date, key }) => (
          <th key={key} className="border-r border-gray-300" style={{ minWidth: '100px' }} ref={(el) => monthRefs.current[key] = el}>
            {key}
          </th>
        ))}
      </tr>
    );
  }, [chartStartDate, chartEndDate, maxRowHeight]);

  const handleDelete = useCallback(async (item) => {
    if (window.confirm(`確定要刪除 ${item.name} 嗎？`)) {
      try {
        await indexedDBServiceInstance.delete(item.tableType, item.id);
        await loadData();
      } catch (error) {
        console.error('刪除失敗:', error);
        setError('刪除資料時發生錯誤');
      }
    }
  }, [loadData]);

  const handleNew = useCallback((simItem = null) => {
    let parentId = null;
    let projectId = null;
    let milestoneId = null;
    let taskId = null;
    let type = 'Project';
    if (simItem) {
      type = simItem.tableType;
      switch (simItem.tableType) {
        case 'Project':
          break;
        case 'Milestone':
          projectId = simItem.projectId;
          break;
        case 'Mission':
          projectId = simItem.projectId;
          milestoneId = simItem.milestoneId;
          break;
        case 'TaskAction':
          taskId = simItem.taskId;
          break;
        default:
          break;
      }
    }

    const newItem = {
      tableType: type,
      name: `新${type === 'Project' ? '專案' : type === 'Milestone' ? '里程碑' : type === 'Mission' ? '任務' : '行動'}`,
      startDate: new Date().toISOString().split('T')[0],
      endDate: new Date().toISOString().split('T')[0],
      status: 'Draft',
      parentId,
      projectId,
      milestoneId,
      taskId
    };

    setEditingItem(newItem);
    setActiveDropdown(null);
  }, []);

  const handleAdd = useCallback((parentItem) => {
    let type;
    let parentId = null;
    let projectId = null;
    let milestoneId = null;
    let taskId = null;

    if (parentItem) {
      parentId = parentItem.id;
      switch (parentItem.tableType) {
        case 'Project':
          type = 'Milestone';
          projectId = parentItem.id;
          break;
        case 'Milestone':
          type = 'Mission';
          projectId = parentItem.projectId;
          milestoneId = parentItem.id;
          break;
        case 'Mission':
          type = 'TaskAction';
          projectId = parentItem.projectId;
          milestoneId = parentItem.milestoneId;
          taskId = parentItem.id;
          break;
        default:
          break;
      }
    }

    const newItem = {
      tableType: type,
      name: `新${type === 'Milestone' ? '里程碑' : type === 'Mission' ? '任務' : '行動'}`,
      startDate: new Date().toISOString().split('T')[0],
      endDate: new Date().toISOString().split('T')[0],
      status: 'Draft',
      parentId,
      projectId,
      milestoneId,
      taskId
    };

    setEditingItem(newItem);
    setActiveDropdown(null);
  }, []);

  const toggleDropdown = useCallback((itemId, event) => {
    event.stopPropagation();
    if (activeDropdown === itemId) {
      setActiveDropdown(null);
    } else {
      const rect = event.currentTarget.getBoundingClientRect();
      setDropdownPosition({
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX
      });
      setActiveDropdown(itemId);
    }
  }, [activeDropdown]);

  // ... 繼續之前的代碼

  const renderDropdown = (item) => {
    if (!item) return null;

    const actions = [
      { label: '新增', action: 'new' },
      { label: '編輯', action: 'edit' },
      { label: '刪除', action: 'delete' }
    ];
    if (item.tableType !== 'TaskAction') {
      actions.push({ label: `新增${item.tableType === 'Project' ? '里程碑' : item.tableType === 'Milestone' ? '任務' : '行動'}`, action: 'add' });
    }

    return (
      <div
        ref={dropdownRef}
        style={{
          position: 'absolute',
          top: `${dropdownPosition.top}px`,
          left: `${dropdownPosition.left}px`,
          zIndex: 1000
        }}
        className="bg-white rounded-md shadow-xl"
      >
        {actions.map(({ label, action }) => (
          <button
            key={action}
            onClick={() => handleDropdownAction(action, item)}
            className="block px-4 py-2 text-sm text-gray-700 hover:bg-blue-500 hover:text-white w-full text-left"
          >
            {label}
          </button>
        ))}
      </div>
    );
  };

  const handleDropdownAction = useCallback((action, item) => {
    setActiveDropdown(null);
    switch (action) {
      case 'edit':
        handleEdit(item);
        break;
      case 'delete':
        handleDelete(item);
        break;
      case 'add':
        handleAdd(item);
        break;
      case 'new':
        handleNew(item);
        break;
      default:
        console.error('未知的動作:', action);
    }
  }, [handleEdit, handleDelete, handleAdd, handleNew]);

  const renderTableRow = useCallback((item, level = 0, isLeftSide = true) => {
    const hasChildren = item.children && item.children.length > 0;
    const backgroundColor = ganttChartService.getBackgroundColorByType(item.tableType);
    const editIcon = ganttChartService.getEditIconByLevel(level);
    const months = [];
    const startDate = new Date(chartStartDate);
    const endDate = new Date(chartEndDate);

    while (startDate <= endDate) {
      months.push(new Date(startDate));
      startDate.setMonth(startDate.getMonth() + 1);
    }

    const rowClassName = `${backgroundColor} ${highlightedRowId === `${item.tableType}-${item.id}` ? 'bg-gray-200' : ''}`;

    return (
      <React.Fragment key={`${item.tableType}-${item.id}`}>
        <tr
          className={`${rowClassName} hover:bg-gray-100 cursor-pointer`}
          style={{ height: `${maxRowHeight}px` }}
          ref={level === 0 && item === data[0] ? (isLeftSide ? leftFirstRowRef : rightFirstRowRef) : null}
          onClick={() => handleRowClick(item)}
        >
          {isLeftSide ? (
            <>
              <td className="px-4 py-2 border-r text-left"
                style={{ width: `${nameColumnWidth}%`, position: 'relative', paddingLeft: `${level * 20 + 8}px`, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {hasChildren && (
                  <button onClick={() => toggleExpand(item.tableType, item.id)} className="mr-2">
                    {isExpanded(item.tableType, item.id) ? '▼' : '▶'}
                  </button>
                )}
                <span className="truncate">{item.name}</span>
                <button
                  onClick={(e) => toggleDropdown(`${item.tableType}-${item.id}`, e)}
                  className="ml-2 focus:outline-none float-right"
                >
                  {editIcon}
                </button>
              </td>
              <td className="px-4 py-2 border-r text-center"
                style={{ width: `${durationColumnWidth}%`, position: 'relative', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {item.duration ? `${item.duration}h` : 'N/A'}
              </td>
              <td className="px-4 py-2 border-r"
                style={{ width: `${statusColumnWidth}%`, position: 'relative', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                <StatusSelector
                currentStatusId={item.statusId}
                onStatusChange={() => {}}
                readOnly={true}
                itemType={item.tableType}
                />
              </td>
            </>
          ) : (
            <>
              {months.map((month, index) => {
                const monthStart = new Date(month.getFullYear(), month.getMonth(), 1);
                const monthEnd = new Date(month.getFullYear(), month.getMonth() + 1, 0);
                return (
                  <td key={index} className="px-4 py-2 border-r relative" style={{ minWidth: '100px', border: 'none' }}>
                    <div className="absolute inset-0">
                      {ganttChartService.renderGanttBarForMonth(item, monthStart, monthEnd, barHeight, maxRowHeight)}
                    </div>
                  </td>
                );
              })}
            </>
          )}
        </tr>
        {isExpanded(item.tableType, item.id) && hasChildren && item.children.map(child => renderTableRow(child, level + 1, isLeftSide))}
      </React.Fragment>
    );
  }, [isExpanded, toggleExpand, nameColumnWidth, durationColumnWidth, statusColumnWidth, maxRowHeight, barHeight, chartStartDate, chartEndDate, toggleDropdown, data, highlightedRowId, handleRowClick]);

  const handleVerticalScroll = (e) => {
    const { target } = e;
    if (target === leftTableBodyRef.current) {
      rightTableBodyRef.current.scrollTop = target.scrollTop;
    } else if (target === rightTableBodyRef.current) {
      leftTableBodyRef.current.scrollTop = target.scrollTop;
    }
  };


  const handleRightTableClick = useCallback((event) => {
    if (rightTableContainerRef.current) {
      const containerRect = rightTableContainerRef.current.getBoundingClientRect();
      const clickX = event.clientX - containerRect.left;
      const containerWidth = containerRect.width;
      
      // 計算需要滾動的距離，使點擊位置位於容器中央
      const scrollLeft = rightTableContainerRef.current.scrollLeft + clickX - containerWidth / 2;
  
      // 使用平滑滾動效果
      rightTableContainerRef.current.scrollTo({
        left: scrollLeft,
        behavior: 'smooth'
      });
    }
  }, []);

  const renderEditInterface = () => {
    if (!editingItem) return null;

    let EditComponent;
    switch (editingItem.tableType) {
      case 'Project':
        EditComponent = ProjectEdit;
        break;
      case 'Milestone':
        EditComponent = MilestoneEdit;
        break;
      case 'Mission':
        EditComponent = MissionEdit;
        break;
      case 'TaskAction':
        EditComponent = ActionEdit;
        break;
      default:
        return null;
    }

    const editContent = (
      <EditComponent
        item={editingItem}
        onSave={handleSave}
        onCancel={handleCancel}
        isNew={!editingItem.id}
        editMode={currentEditMode}
      />
    );

    if (currentEditMode === 'popup') {
      return (
        <ResizablePopup
          isOpen={!!editingItem}
          onClose={handleCancel}
          initialWidth={800}
          initialHeight={600}
          minWidth={400}
          minHeight={300}
        >
          {editContent}
        </ResizablePopup>
      );
    } else {
      return (
        <ResizableDrawer
          isOpen={!!editingItem}
          onClose={handleCancel}
          initialWidth={50}
          minWidth={20}
          maxWidth={80}
        >
          {editContent}
        </ResizableDrawer>
      );
    }
  };

  if (loading) return (
    <div className="flex justify-center items-center h-64">
      <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
    </div>
  );

  if (error) return <div className="text-red-500 text-center py-4 text-xl">{error}</div>;

  return (
    <div className="flex flex-col h-full w-full p-6 justify-center">
      <h1 className="text-2xl font-bold mb-4 text-gray-800">專案甘特圖</h1>
      <div className="mb-4">
        <label htmlFor="table-width" className="block text-gray-700 mb-2">表格寬度 (%):</label>
        <input
          type="range"
          id="table-width"
          min="0"
          max="80"
          value={tableWidth}
          onChange={(e) => setTableWidth(e.target.value)}
          className="w-full"
        />
      </div>
      <div className="flex overflow-hidden">
        <div className="w-1/2" style={{ width: `${tableWidth}%` }}>
          <table className="w-full" ref={leftTableRef}>
            <thead>
              {renderLeftTableHeader()}
            </thead>
            <tbody ref={leftTableBodyRef} onScroll={handleVerticalScroll}>
              {data.map(project => renderTableRow(project, 0, true))}
            </tbody>
          </table>
        </div>
        <div 
          className="w-1/2 overflow-x-auto overflow-y-hidden" 
          style={{ width: `${100 - tableWidth}%` }} 
          ref={rightTableContainerRef} 
          onClick={handleRightTableClick}
        >
          <div style={{ minWidth: 'max-content', position: 'relative' }}>
            <table className="w-full" ref={rightTableRef}>
              <thead>
                {renderRightTableHeader()}
              </thead>
              <tbody ref={rightTableBodyRef} onScroll={handleVerticalScroll}>
                {data.map(project => renderTableRow(project, 0, false))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {activeDropdown !== null && renderDropdown(ganttChartService.findItemByPath(activeDropdown, data))}
      {renderEditInterface()}
      {editingItem && currentEditMode === 'drawer' && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50"
          style={{ zIndex: 999 }}
          onClick={(e) => {
            e.stopPropagation();
            handleCancel();
          }}
        ></div>
      )}
    </div>
  );
};

export default DynamicGanttChart;