// ItemService.js
import axios from "axios";
import {
  getUser as getLocalUser,
  getToken,
  refreshToken,
} from "../../services/authService";
import EventEmitter from "events";

const AUTH_TOKEN_KEY = "token";
const AUTH_USER_KEY = "user";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

class ItemService extends EventEmitter {
  constructor() {
    super();
    this.endpoints = {
      TeamMember: "/api/Collaboration/teammembers",
      Project: "/api/Project/projects",
      Mission: "/api/Project/missions",
      Milestone: "/api/Project/milestones",
      TaskAction: "/api/Project/taskactions",
      Type: "/api/Collaboration/types",
      TypeCorrelation: "/api/Collaboration/typesCorrelations",
      TaskRating: "/api/Collaboration/taskratings",
      Tag: "/api/Collaboration/tags",
      TagRelation: "/api/Collaboration/tagrelations",
      StatusOption: "/api/Collaboration/statusoptions",
      Comment: "/api/Collaboration/comments",
      AccessList: "/api/Project/AccessList",
      User: "/api/Users/related",
      UserCompanyRelation: "/api/Relationship/user-company-relations",
      Company: "/api/Relationship/companies",
      Supplier: "/api/Relationship/suppliers",
      Tracking: "/api/Users/tracking",
      Budget: "/api/Billing/budget",
      Transaction:"/api/Account/wallettransactions",
      Wallet: "/api/Account/wallets"
    };
    this.consecutiveErrors = 0;
    this.MAX_CONSECUTIVE_ERRORS = 10; // 可以根據需要調整這個數值
  }
  resetErrorCount() {
    this.consecutiveErrors = 0;
  }

  handleError() {
    this.consecutiveErrors++;
    if (this.consecutiveErrors >= this.MAX_CONSECUTIVE_ERRORS) {
      console.log(
        `連續發生 ${this.MAX_CONSECUTIVE_ERRORS} 次錯誤，執行登出操作`
      );
      this.emit("forceLogout"); // 發射強制登出事件
      localStorage.removeItem(AUTH_TOKEN_KEY);
      localStorage.removeItem(AUTH_USER_KEY);
      this.consecutiveErrors = 0; // 重置錯誤計數
    }
  }
  async createItem(storeName, item) {
    try {
      const endpoint = this.endpoints[storeName];
      if (!endpoint) {
        throw new Error(`未知的存儲名稱: ${storeName}`);
      }
      item.id = null;
      const stringifiedItem = JSON.stringify(item);
      let data = { Content: stringifiedItem };
      const response = await axios.put(`${API_BASE_URL}${endpoint}`, data, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          user: getLocalUser()?.userId,
          "Content-Type": "application/json",
        },
      });

      const newItem = response.data;
      this.resetErrorCount(); // 成功時重置錯誤計數
      await refreshToken();
      return { success: true, data: newItem };
    } catch (error) {
      this.handleError();
      console.error(`創建 ${storeName} 失敗:`, error);
      return { success: false, error: error.message };
    }
  }

  async deleteItem(storeName, id) {
    try {
      const endpoint = this.endpoints[storeName];
      if (!endpoint) {
        throw new Error(`未知的存儲名稱: ${storeName}`);
      }
      const response = await axios.delete(`${API_BASE_URL}${endpoint}/${id}`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          user: getLocalUser()?.userId,
        },
      });
      this.resetErrorCount(); // 成功時重置錯誤計數
      await refreshToken();
      return response;
    } catch (error) {
      this.handleError();
      console.error(`更新 ${storeName} 失敗:`, error);
      return { success: false, error: error.message };
    }
  }

  async updateItem(storeName, item, contentType) {
    try {
      const endpoint = this.endpoints[storeName];
      if (!endpoint) {
        throw new Error(`未知的存儲名稱: ${storeName}`);
      }
      const stringifiedItem = JSON.stringify(item);
      let data = { Content: stringifiedItem, ContentType: contentType };
      const response = await axios.put(
        `${API_BASE_URL}${endpoint}`,
        data, // 這裡可以是需要發送的請求體
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
            user: getLocalUser()?.userId,
          },
        }
      );
      const updatedItem = response.data;
      this.resetErrorCount(); // 成功時重置錯誤計數
      await refreshToken();
      return { success: true, data: updatedItem };
    } catch (error) {
      this.handleError();
      console.error(`更新 ${storeName} 失敗:`, error);
      return { success: false, error: error.message };
    }
  }

  async getItem(token, currentUser, storeName, id) {
    try {
      const endpoint = this.endpoints[storeName];
      if (!endpoint) {
        throw new Error(`未知的存儲名稱: ${storeName}`);
      }

      const response = await axios.get(`${API_BASE_URL}${endpoint}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          user: currentUser?.userId,
        },
      });

      this.resetErrorCount(); // 成功時重置錯誤計數
      return response.data;
    } catch (error) {
      this.handleError();
      console.error(`獲取單個 ${storeName} 失敗:`, error);
      throw error;
    }
  }

  // 以下是原有的獲取方法，保持不變

  async getTeamMembers(token, currentUser, lastDataUpdate) {
    return this.fetchAndStore(token, currentUser, "TeamMember", lastDataUpdate);
  }

  async getProject(token, currentUser, lastDataUpdate) {
    return this.fetchAndStore(token, currentUser, "Project", lastDataUpdate);
  }

  async getMissions(token, currentUser, lastDataUpdate) {
    return this.fetchAndStore(token, currentUser, "Mission", lastDataUpdate);
  }

  async getMilestones(token, currentUser, lastDataUpdate) {
    return this.fetchAndStore(token, currentUser, "Milestone", lastDataUpdate);
  }

  async getTaskActions(token, currentUser, lastDataUpdate) {
    return this.fetchAndStore(token, currentUser, "TaskAction", lastDataUpdate);
  }

  async getActionTypes(token, currentUser, lastDataUpdate) {
    return this.fetchAndStore(token, currentUser, "Type", lastDataUpdate);
  }
  async getTypesCorrelations(token, currentUser, lastDataUpdate) {
    return this.fetchAndStore(
      token,
      currentUser,
      "TypeCorrelation",
      lastDataUpdate
    );
  }
  async getTaskRatings(token, currentUser, lastDataUpdate) {
    return this.fetchAndStore(token, currentUser, "TaskRating", lastDataUpdate);
  }

  async getTags(token, currentUser, lastDataUpdate) {
    return this.fetchAndStore(token, currentUser, "Tag", lastDataUpdate);
  }

  async getTagRelations(token, currentUser, lastDataUpdate) {
    return this.fetchAndStore(
      token,
      currentUser,
      "TagRelation",
      lastDataUpdate
    );
  }

  async getStatusOptions(token, currentUser, lastDataUpdate) {
    return this.fetchAndStore(
      token,
      currentUser,
      "StatusOption",
      lastDataUpdate
    );
  }

  async getComments(token, currentUser, lastDataUpdate) {
    return this.fetchAndStore(token, currentUser, "Comment", lastDataUpdate);
  }

  async getAccess(token, currentUser, lastDataUpdate) {
    return this.fetchAndStore(token, currentUser, "AccessList", lastDataUpdate);
  }

  async getUser(token, currentUser, lastDataUpdate) {
    return this.fetchAndStore(token, currentUser, "User", lastDataUpdate);
  }

  async getUserCompanyRelation(token, currentUser, lastDataUpdate) {
    return this.fetchAndStore(
      getToken(),
      getLocalUser(),
      "UserCompanyRelation",
      lastDataUpdate
    );
  }
  async getCompany() {
    return this.fetch("Company");
  }
  async getSupplier() {
    return this.fetch("Supplier");
  }
  async getCompanyById(token, currentUser, id) {
    return this.fetchId(token, currentUser, "Company", id);
  }

  async getUserByEmail(email) {
    return this.fetchString("/api/Users", email, "email");
  }
  async getCompanyByAccount(id) {
    return this.fetchString(
      this.endpoints["Company"],
      String(id),
      "accountaccess"
    );
  }
  async getMemberByCompany(id) {
    return this.fetchString(
      this.endpoints["UserCompanyRelation"],
      String(id),
      "company"
    );
  }

  async getTaskActionByTimeList(data) {
    return this.fetchString(this.endpoints["TaskAction"], data, "timelist");
  }

  async getProjectTree(id) {
    return this.fetchString(this.endpoints["Project"], String(id), "tree");
  }

  async getMilestoneTree(id) {
    return this.fetchString(this.endpoints["Milestone"], String(id), "tree");
  }
  async getVersion(filt, id) {
    return this.fetchString(this.endpoints[filt], String(id), "version");
  }
  async getTypes(filt, data) {
    return this.fetchString(this.endpoints["Type"], data, filt);
  }
  async getStatus(filt, data) {
    return this.fetchString(this.endpoints["StatusOption"], data, filt);
  }

  async getAccessBool(filt, data) {
    return this.fetchString(this.endpoints["AccessList"], data, filt);
  }
  async getCommentString(filt, data) {
    return this.fetchString(this.endpoints["Comment"], data, filt);
  }

  async getTaskActionString(filt, data) {
    return this.fetchString(this.endpoints["TaskAction"], data, filt);
  }
  async getCompanyByString(filt, data) {
    return this.fetchString(this.endpoints["Company"], data, filt);
  }
  async getProjectString(filt, data) {
    return this.fetchString(this.endpoints["Project"], data, filt);
  }

  async getMilestoneString(filt, data) {
    return this.fetchString(this.endpoints["Milestone"], data, filt);
  }
  async getMissionString(filt, data) {
    return this.fetchString(this.endpoints["Mission"], data, filt);
  }
  async getChildString(filt, data) {
    return this.fetchString(this.endpoints[filt], data, "parentIdDetail");
  }
  async getTeamMemberString(filt, data) {
    return this.fetchString(this.endpoints["TeamMember"], data, filt);
  }
  async getWalletString(filt, data){
    return this.fetchString(this.endpoints["Wallet"], data, filt);
  }
  async fetchId(token, currentUser, storeName, id) {
    try {
      const endpoint = this.endpoints[storeName];
      if (!endpoint) {
        throw new Error(`未知的存儲名稱: ${storeName}`);
      }
      const response = await axios.get(`${API_BASE_URL}${endpoint}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          user: currentUser?.userId,
        },
      });

      const data = response.data;
      this.resetErrorCount(); // 成功時重置錯誤計數
      return data;
    } catch (error) {
      this.handleError();
      console.error(`獲取 ${storeName} 失敗:`, error);
      throw error;
    }
  }

  async fetchString(endpoint, stringdata, stringtype) {
    try {
      const response = await axios.get(`${API_BASE_URL}${endpoint}/string`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          user: getLocalUser()?.userId,
          qdata: stringdata,
          qtype: stringtype,
        },
      });
      const data = response.data;
      this.resetErrorCount(); // 成功時重置錯誤計數
      return data;
    } catch (error) {
      this.handleError();
      console.error(`失敗:`, error);
      throw error;
    }
  }
  async fetch(storeName) {
    try {
      const endpoint = this.endpoints[storeName];
      if (!endpoint) {
        throw new Error(`未知的存儲名稱: ${storeName}`);
      }

      const response = await axios.get(`${API_BASE_URL}${endpoint}`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          user: getLocalUser()?.userId,
        },
      });
      const data = response.data;
      this.resetErrorCount(); // 成功時重置錯誤計數
      return data;
    } catch (error) {
      this.handleError();
      console.error(`獲取 ${storeName} 失敗:`, error);
      throw error;
    }
  }
  async fetchAndStore(token, currentUser, storeName, lastDataUpdate) {
    try {
      const endpoint = this.endpoints[storeName];
      if (!endpoint) {
        throw new Error(`未知的存儲名稱: ${storeName}`);
      }

      const headers = {
        Authorization: `Bearer ${token}`,
        user: currentUser?.userId,
      };
      if (lastDataUpdate) {
        if (typeof lastDataUpdate !== "string")
          headers["lastUpdate"] = lastDataUpdate.toISOString();
        else headers["lastUpdate"] = lastDataUpdate;
      }
      const response = await axios.get(`${API_BASE_URL}${endpoint}`, {
        headers: headers,
      });

      const data = response.data;
      this.resetErrorCount(); // 成功時重置錯誤計數
      return data;
    } catch (error) {
      this.handleError();
      console.error(`獲取 ${storeName} 失敗:`, error);
      throw error;
    }
  }
}

const itemService = new ItemService();

export default itemService;
