import React, { useState, useEffect, useCallback } from 'react';

const ResizableDrawer = ({ 
  isOpen, 
  onClose, 
  initialWidth = 50, 
  minWidth = 20, 
  maxWidth = 95, 
  windowWidth = window.innerWidth,
  topOffset = true,  // 新增上方留白參數
  backgroundColor = 'white',  // 新增底色參數
  children 
}) => {
  const [drawerWidth, setDrawerWidth] = useState(initialWidth * windowWidth / 100);
  const [isDragging, setIsDragging] = useState(false);

  const updateHeaderHeight = useCallback(() => {
    const header = document.querySelector('header');
    return header ? header.offsetHeight : 0;
  }, []);

  const [headerHeight, setHeaderHeight] = useState(updateHeaderHeight);

  useEffect(() => {
    const handleResize = () => {
      setHeaderHeight(updateHeaderHeight());
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [updateHeaderHeight]);

  useEffect(() => {
    const minWidthPx = minWidth * windowWidth / 100;
    const maxWidthPx = maxWidth * windowWidth / 100;

    const handleMouseMove = (e) => {
      if (isDragging) {
        const testX = (window.innerWidth - e.clientX);
        const finalWidth = Math.max(minWidthPx, Math.min(maxWidthPx, testX));
        setDrawerWidth(finalWidth);
      }
    };

    const handleMouseUp = () => {
      setIsDragging(false);
    };

    if (isDragging) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging, minWidth, maxWidth, windowWidth]);

  return (
    <div 
      className={`fixed inset-y-0 right-0 shadow-2xl transform ${isOpen ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-300 ease-in-out overflow-hidden`}
      style={{ 
        width: `${drawerWidth}px`,
        top: `${topOffset ? headerHeight : 0}px`,  // 增加上方留白
        height: `calc(100vh - ${headerHeight}px)`,
        zIndex: 1000,
        backgroundColor: backgroundColor // 設置底色
      }}
    >
      <div 
        className="absolute left-0 top-0 bottom-0 w-1 bg-gray-300 cursor-ew-resize"
        style={{ left: '0' }} // 確保調整大小手柄位置不跑掉
        onMouseDown={(e) => {
          e.preventDefault();
          setIsDragging(true);
        }}
      ></div>
      <div className="h-full overflow-y-auto" style={{ backgroundColor }}> {/* 確保內容區域有白色背景 */}
        <div className="p-6">
          <button 
            onClick={onClose} 
            className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 transition-colors duration-200"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
          {React.cloneElement(children, { drawerWidth })} {/* 傳遞 drawerWidth 作為 prop */}
        </div>
      </div>
    </div>
  );
};

export default ResizableDrawer;
