import React, { useState, useEffect, useCallback } from 'react';

const ResizablePopup = ({ 
  isOpen, 
  onClose, 
  initialWidth = 600, 
  initialHeight = 400,
  minWidth = 200, 
  minHeight = 150,
  children 
}) => {
  const [size, setSize] = useState({ width: initialWidth, height: initialHeight });
  const [position, setPosition] = useState({ x: window.innerWidth / 2 - initialWidth / 2, y: window.innerHeight / 2 - initialHeight / 2 });
  const [isDragging, setIsDragging] = useState(false);
  const [isResizing, setIsResizing] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });

  const handleMouseDown = useCallback((event, action) => {
    if (action === 'drag') {
      setIsDragging(true);
    } else if (action === 'resize') {
      setIsResizing(true);
    }
    setDragStart({ x: event.clientX, y: event.clientY });
  }, []);

  const handleMouseUp = useCallback(() => {
    setIsDragging(false);
    setIsResizing(false);
  }, []);

  const handleMouseMove = useCallback((event) => {
    if (isDragging) {
      const dx = event.clientX - dragStart.x;
      const dy = event.clientY - dragStart.y;
      setPosition(prev => ({
        x: Math.max(0, Math.min(prev.x + dx, window.innerWidth - size.width)),
        y: Math.max(0, Math.min(prev.y + dy, window.innerHeight - size.height))
      }));
      setDragStart({ x: event.clientX, y: event.clientY });
    } else if (isResizing) {
      const dx = event.clientX - dragStart.x;
      const dy = event.clientY - dragStart.y;
      setSize(prev => ({
        width: Math.max(minWidth, Math.min(prev.width + dx, window.innerWidth - position.x)),
        height: Math.max(minHeight, Math.min(prev.height + dy, window.innerHeight - position.y))
      }));
      setDragStart({ x: event.clientX, y: event.clientY });
    }
  }, [isDragging, isResizing, dragStart, size, position, minWidth, minHeight]);

  useEffect(() => {
    if (isDragging || isResizing) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    }
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging, isResizing, handleMouseMove, handleMouseUp]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div 
        className="bg-white rounded-lg shadow-xl overflow-hidden absolute"
        style={{ 
          width: `${size.width}px`, 
          height: `${size.height}px`,
          left: `${position.x}px`,
          top: `${position.y}px`
        }}
      >
        <div 
          className="p-4 bg-gray-100 cursor-move flex justify-between items-center"
          onMouseDown={(e) => handleMouseDown(e, 'drag')}
        >
          <h2 className="text-lg font-semibold">編輯</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div className="p-4 overflow-auto" style={{ height: 'calc(100% - 60px)' }}>
          {children}
        </div>
        <div 
          className="absolute bottom-0 right-0 w-4 h-4 bg-gray-300 cursor-se-resize"
          onMouseDown={(e) => handleMouseDown(e, 'resize')}
        ></div>
      </div>
    </div>
  );
};

export default ResizablePopup;