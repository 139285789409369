import React, { useState, useEffect } from "react";
import ItemEdit from "../common/ItemEdit";
import { accessCheck } from "../common/Access";
import { getCurrentLocalDateTime } from "../../utils/dateUtils";
import itemService from "../../services/db/ItemService";

const STORAGE_KEY = 'runningActionIds';


const ActionEdit = ({ item, onSave, onCancel, isNew, editMode }) => {
  const [preVersion, setPreVersion] = useState("v?.?");
  const [lastVersion, setLastVersion] = useState(0);
  const [access, setAccess] = useState(0);
  const [readOnly, setReadOnly] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [editingItem, setEditingItem] = useState(null);
  useEffect(() => {
    const fetchVersions = async () => {
      if (item.parentId) {
        try {
          const result = await itemService.getVersion(
            "TaskAction",
            item.parentId
          );
          if (Array.isArray(result) && result.length > 0) {
            const version = result[0];
            setLastVersion(version.maxVersion ?? 0);
            setPreVersion(version.preVersion ?? "v?.?");
          }
          if (isNew) {
            setAccess(5);
            setReadOnly(false);
          } else {
            const accessQ = await accessCheck("Mission", item.parentId);
            if (accessQ) {
              setAccess(accessQ.access);
              setReadOnly(accessQ.readOnly);
            }
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    };
    const checkItemInfo = async () => {
      let tempItem = { ...item, tableType: "TaskAction" };
      if (!item.id || typeof item.id !== "number") {
        tempItem.name = "新動作";
        tempItem.version = lastVersion;
        tempItem.startDate = getCurrentLocalDateTime();
      }
      setEditingItem(tempItem);
    };
    checkItemInfo();
    fetchVersions();
  }, [item, isNew, lastVersion]);

  const handleSave = async (updatedItem) => {
    if (updatedItem && access >= 2) {
       await onSave(updatedItem);
      const storedActionIds = JSON.parse(
        localStorage.getItem(STORAGE_KEY) || "[]"
      );

      if (updatedItem.endDate=== null || updatedItem.endDate === undefined) {
        if (!storedActionIds.includes(updatedItem.id)) {
          storedActionIds.push(updatedItem.id);
        }
      } else {
        const index = storedActionIds.indexOf(updatedItem.id);
        if (index > -1) {
          storedActionIds.splice(index, 1);
        }
      }
      localStorage.setItem(STORAGE_KEY, JSON.stringify(storedActionIds));

    } else {
      console.warn("權限不足");
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <ItemEdit
      item={editingItem}
      onSave={handleSave}
      onCancel={onCancel}
      isNew={isNew}
      editMode={editMode}
      readOnly={readOnly}
      itemType={item.tableType}
      preVersion={preVersion}
      lastVersion={lastVersion}
      access={access}
    />
  );
};

export default ActionEdit;

/*
    public enum PermissionType
    {
        DeleteItem = 5,
        DeleteSubItems = 4,
        AddSubItems = 3,
        WriteItem = 2,
        ReadSubItems = 1,
        ReadItem = 0
    }
*/
