// TabSwitch.js
import React from 'react';

const TabSwitch = ({ activeTab, setActiveTab, isNew, itemType }) => {
  return (
    <div className="mb-4">
      <nav className="flex space-x-4" aria-label="Tabs">
        <button
          onClick={() => setActiveTab('edit')}
          className={`px-3 py-2 font-medium text-sm rounded-md ${
            activeTab === 'edit'
              ? 'bg-indigo-100 text-indigo-700'
              : 'text-gray-500 hover:text-gray-700'
          }`}
        >
          編輯{itemType}
        </button>
        {!isNew && (
          <button
            onClick={() => setActiveTab('history')}
            className={`px-3 py-2 font-medium text-sm rounded-md ${
              activeTab === 'history'
                ? 'bg-indigo-100 text-indigo-700'
                : 'text-gray-500 hover:text-gray-700'
            }`}
          >
            查看歷史
          </button>
        )}
      </nav>
    </div>
  );
};

export default TabSwitch;