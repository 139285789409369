import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  TextField,
  Typography,
  Button,
  Card,
  CardContent,
  CardActions,
  List,
  ListItem,
  Chip,
  Stack,
  CircularProgress,
  TableContainer,
  TableRow,
  Paper,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableSortLabel,
  TablePagination,
} from "@mui/material";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import { formatDate } from "../../utils/dateUtils";
import { convertToDTO } from "../../types/relationsTypes";
import itemService from "../../services/db/ItemService";
import ItemEditModal from "../common/ItemEditModal";
import { getColorStyles, companyRoleTypes } from "../../utils/utils";
import {
  transactionTypeTranslations,
  relatedEntityTypeTranslations,
  translate,
} from "../../utils/transaction";
import { getUser } from "../../services/authService";
const columns = [
  { id: "createdAt", label: "交易日期", minWidth: 100, align: "left" },
  { id: "transactionType", label: "操作", minWidth: 100, align: "center" },
  { id: "relatedEntityType", label: "類別", minWidth: 100, align: "center" },
  { id: "debit", label: "支出", minWidth: 100, align: "right" },
  { id: "credit", label: "收入", minWidth: 100, align: "right" },
  { id: "balance", label: "餘額", minWidth: 150, align: "right" },
  { id: "createdBy", label: "人員", minWidth: 100, align: "center" },
  { id: "description", label: "備註", minWidth: 200, align: "left" },
];

const LocalizedTablePagination = ({ ...props }) => {
  return (
    <TablePagination
      {...props}
      labelRowsPerPage="每頁筆數："
      labelDisplayedRows={({ from, to, count }) =>
        `${from}-${to} 共 ${count} 筆`
      }
      getItemAriaLabel={(type) => {
        if (type === "first") {
          return "跳至第一頁";
        }
        if (type === "last") {
          return "跳至最後一頁";
        }
        if (type === "next") {
          return "下一頁";
        }
        return "上一頁";
      }}
    />
  );
};
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  color: theme.palette.common.black,
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
}));
const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-root": {
            height: "40px",
          },
          "& .MuiInputLabel-root": {
            transform: "translate(14px, -9px) scale(0.75)",
            background: "#fff",
            padding: "0 4px",
          },
          "& .MuiInputLabel-shrink": {
            transform: "translate(14px, -9px) scale(0.75)",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          height: "40px",
        },
        input: {
          padding: "10px 14px",
        },
      },
    },
  },
});

const StyledListItem = styled(ListItem)(({ theme, isformer }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  borderLeft: `4px solid ${
    isformer ? theme.palette.grey[400] : theme.palette.primary.main
  }`,
  paddingLeft: theme.spacing(2),
  marginBottom: theme.spacing(2),
  opacity: isformer ? 0.6 : 1,
  backgroundColor: isformer ? theme.palette.grey[100] : "inherit",
  "&:hover": {
    backgroundColor: isformer
      ? theme.palette.grey[200]
      : theme.palette.action.hover,
  },
}));

const ItemHeader = styled(Box)({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  alignItems: "center",
});

const ItemName = styled(Typography)({
  fontWeight: "bold",
  flexGrow: 1,
});

const ActionButtons = styled(Box)({
  display: "flex",
});

const ItemDetails = styled(Typography)({
  marginTop: "4px",
  color: "rgba(0, 0, 0, 0.6)",
});

const StyledChip = styled(Chip)(({ colorstyle }) => ({
  marginRight: "4px",
  backgroundColor: colorstyle.backgroundColor,
  color: colorstyle.color,
  "& .MuiChip-label": {
    color: colorstyle.color,
  },
}));
const CustomTableCell = ({ column, value, align }) => {
  const renderCellContent = () => {
    switch (column.id) {
      case "createdAt":
        return formatDate(value);
      case "debit":
        return value < 0
          ? `${new Intl.NumberFormat("en-US").format(-value)}`
          : "";
      case "credit":
        return value > 0
          ? `${new Intl.NumberFormat("en-US").format(value)}`
          : "";
      case "balance":
        return `${new Intl.NumberFormat("en-US").format(value)}`;
      case "transactionType":
        return translate(value, transactionTypeTranslations);
      case "relatedEntityType":
        return translate(value, relatedEntityTypeTranslations);
      default:
        return value;
    }
  };

  return (
    <StyledTableCell align={align || column.align}>
      {renderCellContent()}
    </StyledTableCell>
  );
};
const CompanyEdit = ({ item, onSave, onCancel }) => {
  const [editedItem, setEditedItem] = useState(item);
  const [isInitialized, setIsInitialized] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [isChanged, setIsChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("createdAt");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [tableType, setTableType] = useState(null);
  const [walletInfo, setWalletInfo] = useState(null);

  const loadCompanyRelation = useCallback(async (companyID, walletID) => {
    if (companyID) {
      setLoading(true);
      try {
        const [employeeData, transactionData] = await Promise.all([
          itemService.getMemberByCompany(companyID),
          itemService.getCompanyByString("transaction", String(companyID)),
        ]);

        setEmployees(employeeData);
        setTransactions(transactionData);

        if (getUser().membershipLevelId === 999 && walletID) {
          const wallet = await itemService.getWalletString("id", walletID);
          setWalletInfo(wallet);
        }
      } catch (error) {
        console.error("載入資料時發生錯誤:", error);
        setError("載入資料時發生錯誤");
      } finally {
        setLoading(false);
      }
    }
  }, []);

  useEffect(() => {
    if (item.companyID && !isInitialized) {
      setEditedItem(item);
      loadCompanyRelation(item.companyID, item.walletID);
      setIsInitialized(true);
    }
    const CUSTOM_EVENT_NAME = "companyDataUpdated";
    const handleDataUpdated = () => {
      if (item.companyID) {
        loadCompanyRelation();
      }
    };

    window.addEventListener(CUSTOM_EVENT_NAME, handleDataUpdated);

    return () => {
      window.removeEventListener(CUSTOM_EVENT_NAME, handleDataUpdated);
    };
  }, [item, loadCompanyRelation, isInitialized]);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sortedAndPaginatedTransactions = React.useMemo(() => {
    const comparator = (a, b) => {
      let aValue = a[orderBy];
      let bValue = b[orderBy];

      // 特殊處理日期和數字類型
      if (orderBy === "createdAt") {
        aValue = new Date(aValue).getTime();
        bValue = new Date(bValue).getTime();
      } else if (["debit", "credit", "balance"].includes(orderBy)) {
        aValue = parseFloat(aValue) || 0;
        bValue = parseFloat(bValue) || 0;
      }

      if (bValue < aValue) {
        return order === "asc" ? 1 : -1;
      }
      if (bValue > aValue) {
        return order === "asc" ? -1 : 1;
      }
      return 0;
    };

    return [...transactions]
      .sort(comparator)
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }, [transactions, order, orderBy, page, rowsPerPage]);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setLoading(true);
      try {
        const dto = await convertToDTO({
          tableType: "Company",
          data: editedItem,
        });
        const response = item.companyID
          ? await itemService.updateItem(item.tableType, dto, "")
          : await itemService.createItem(item.tableType, dto);

        if (!response.success) {
          throw new Error(`上傳失敗: ${response.error}`);
        }

        const result = convertToDTO({
          tableType: item.tableType,
          data: response.data,
        });
        if (walletInfo && editedItem.credit) {
          const wallet = {
            ...walletInfo,
            credit: editedItem.credit,
          };
          setWalletInfo(wallet);
          await itemService.updateItem("Wallet", wallet, "");
        }
        setEditedItem({ ...result });
        setIsChanged(false);
        
        onSave(result);
      } catch (error) {
        console.error("保存公司資料時發生錯誤:", error);
        setError("保存公司資料時發生錯誤");
      } finally {
        setLoading(false);
      }
    },
    [editedItem, item, onSave,walletInfo]
  );

  const handleChange = (e) => {
    setEditedItem((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
    setIsChanged(true);
  };

  const renderChips = useCallback((employee) => {
    const chips = [];
    if (employee.roleType != null) {
      chips.push(
        <StyledChip
          key="type"
          label={
            companyRoleTypes.find((role) => role.value === employee.roleType)
              ?.label || employee.roleType
          }
          size="small"
          colorstyle={getColorStyles("bg-blue-200 text-blue-800")}
        />
      );
    }
    if (employee.department) {
      chips.push(
        <StyledChip
          key="department"
          label={employee.department}
          size="small"
          colorstyle={getColorStyles("bg-blue-200 text-blue-800")}
        />
      );
    }
    return chips;
  }, []);

  const handleNewTransaction = () => {
    if (editedItem && editedItem.walletID) {
      setSelectedItem({ parentId: editedItem.walletID });
      setIsEditModalOpen(true);
      setTableType("Transaction");
    }
  };

  const handleNewEmployee = () => {
    if (editedItem && editedItem.companyID) {
      setSelectedItem({
        parentId: editedItem.companyID,
        companyID: editedItem.companyID,
      });
      setTableType("UserCompanyRelation");
      setIsEditModalOpen(true);
    }
  };

  const handleCloseModal = () => {
    setIsEditModalOpen(false);
    setSelectedItem(null);
  };

  const handleSaveEmployee = () => {
    setIsEditModalOpen(false);
    loadCompanyRelation();
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="200px"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ width: "100%", mb: 2 }}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Card sx={{ margin: "auto", boxShadow: 3 }}>
        <CardContent>
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{ "& .MuiTextField-root": { mb: 1 } }}
          >
            <Stack spacing={2}>
              <TextField
                fullWidth
                name="companyName"
                label="公司名稱"
                value={editedItem.companyName || ""}
                onChange={handleChange}
                variant="outlined"
                required
              />
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  name="shortName"
                  label="簡稱"
                  value={editedItem.shortName || ""}
                  onChange={handleChange}
                  variant="outlined"
                />
                <TextField
                  fullWidth
                  name="registerId"
                  label="統一編號"
                  value={editedItem.registerId || ""}
                  onChange={handleChange}
                  variant="outlined"
                  required
                />
              </Stack>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  name="industry"
                  label="產業"
                  value={editedItem.industry || ""}
                  onChange={handleChange}
                  variant="outlined"
                />
                <TextField
                  fullWidth
                  name="address"
                  label="地址"
                  value={editedItem.address || ""}
                  onChange={handleChange}
                  variant="outlined"
                />
              </Stack>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  name="phoneNumber"
                  label="電話"
                  value={editedItem.phoneNumber || ""}
                  onChange={handleChange}
                  variant="outlined"
                />
                <TextField
                  fullWidth
                  name="website"
                  label="網站"
                  value={editedItem.website || ""}
                  onChange={handleChange}
                  variant="outlined"
                />
              </Stack>
              {walletInfo && (
                <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                  <TextField
                    fullWidth
                    name="pointBalance"
                    label="餘額"
                    value={walletInfo.pointBalance || ""}
                    disabled
                    variant="outlined"
                  />
                  <TextField
                    fullWidth
                    name="credit"
                    label="信用額度"
                    value={
                      editedItem.credit
                        ? editedItem.credit
                        : walletInfo.credit || ""
                    }
                    onChange={handleChange}
                    variant="outlined"
                  />
                </Stack>
              )}
            </Stack>
          </Box>
        </CardContent>

        {isChanged && (
          <CardActions sx={{ justifyContent: "center", pb: 3 }}>
            <Button
              onClick={onCancel}
              variant="outlined"
              color="secondary"
              sx={{ mr: 2, minWidth: 100 }}
            >
              取消
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{ minWidth: 100 }}
              onClick={handleSubmit}
            >
              儲存
            </Button>
          </CardActions>
        )}
        <CardContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h5" component="h3" color="text.primary">
              員工列表
            </Typography>
            <button
              type="button"
              onClick={handleNewEmployee}
              className="text-blue-600 hover:text-blue-800 transition-colors duration-200"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                />
              </svg>
            </button>
          </Box>
          <List sx={{ padding: 0 }}>
            {employees.map((employee) => (
              <StyledListItem
                key={employee.id}
                sx={{ paddingBottom: 0.5 }}
                isformer={!!employee.quitDate}
              >
                <ItemHeader>
                  <ItemName variant="subtitle1">
                    {employee.name}
                    {"    "}
                    {renderChips(employee)}
                  </ItemName>
                  <ActionButtons>
                    <button
                      onClick={() => {
                        setSelectedItem(employee);
                        setIsEditModalOpen(true);
                      }}
                      className="text-blue-600 hover:text-blue-800 transition-colors duration-200"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                      </svg>
                    </button>
                  </ActionButtons>
                </ItemHeader>
                <ItemDetails variant="body2">
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: 0.5,
                    }}
                  >
                    <Box sx={{ flexShrink: 0, minWidth: "150px" }}>
                      <Typography variant="body2" color="text.secondary">
                        電話: {employee.contactNumber || "無"}
                      </Typography>
                    </Box>
                    <Box sx={{ flexGrow: 1, textAlign: "right" }}>
                      <Typography variant="body2" color="text.secondary">
                        Email: {employee.companyEmail || "無"}
                      </Typography>
                    </Box>
                  </Box>
                  {employee.address && (
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ marginBottom: 0.5 }}
                    >
                      地址: {employee.address}
                    </Typography>
                  )}
                  {employee.quitDate && (
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ marginBottom: 0.5 }}
                    >
                      離職日期: {formatDate(employee.quitDate)}
                    </Typography>
                  )}
                </ItemDetails>
              </StyledListItem>
            ))}
          </List>
        </CardContent>
        <CardContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h5" component="h3" color="text.primary">
              出入帳列表
            </Typography>
            {getUser().membershipLevelId === 999 && (
              <button
                type="button"
                onClick={handleNewTransaction}
                className="text-blue-600 hover:text-blue-800 transition-colors duration-200"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                  />
                </svg>
              </button>
            )}
          </Box>
          {transactions.length > 0 ? (
            <TableContainer component={Paper}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        <TableSortLabel
                          active={orderBy === column.id}
                          direction={orderBy === column.id ? order : "asc"}
                          onClick={() => handleRequestSort(column.id)}
                        >
                          {column.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedAndPaginatedTransactions.map((transac, index) => (
                    <StyledTableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={index}
                    >
                      {columns.map((column) => (
                        <CustomTableCell
                          key={column.id}
                          column={column}
                          value={transac[column.id]}
                          align={column.align || "left"}
                        />
                      ))}
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
              <LocalizedTablePagination
                rowsPerPageOptions={[10, 20, 50]}
                component="div"
                count={transactions.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          ) : (
            <Typography variant="body1" sx={{ mt: 2, textAlign: "center" }}>
              暫無交易記錄
            </Typography>
          )}
        </CardContent>
        {error && (
          <Typography color="error" sx={{ mt: 2, textAlign: "center" }}>
            {error}
          </Typography>
        )}
        {isEditModalOpen && selectedItem && (
          <ItemEditModal
            currentItem={selectedItem}
            ItemEditComponent={tableType}
            readOnly={false}
            tableType={tableType}
            parentId={selectedItem.parentId}
            setErrorMessage={setError}
            onSave={handleSaveEmployee}
            onClose={handleCloseModal}
          />
        )}
      </Card>
    </ThemeProvider>
  );
};

export default CompanyEdit;
